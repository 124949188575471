import { render, staticRenderFns } from "./suspiciousItem.vue?vue&type=template&id=4cdeb8ca&scoped=true&"
import script from "./suspiciousItem.vue?vue&type=script&lang=js&"
export * from "./suspiciousItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdeb8ca",
  null
  
)

export default component.exports