<template>
  <GeneralCard custom-card-style="padding: 16px !important;">
    <div class="d-flex align-center">
      <div class="font-size-11">
        <div class="font-weight-bold">
          <span> {{ (item?.amount / 10) | currency }} {{ currencyName }} </span>
          |
          <span>شناسه واریز: {{ item?.cash_in_code }}</span>
          |
          <span>{{ item?.customer_name }}</span>
        </div>
        <div class="van_color03--text my-2">
          <span>تاریخ ایجاد: {{ transactionAt }}</span>
          |
          <span>تعیین وضعیت تا {{ expireAt }}</span>
        </div>
        <div v-if="item.description" class="van_color03--text my-2">
          توضیحات: {{ item.description }}
        </div>
        <v-divider class="van_color11 mb-2" />
        <div class="van_color03--text d-flex font-size-11">
          <template v-if="item?.iban">
            <span>{{ item?.iban }}</span>
            <span class="pr-1 pl-2">
              <img
                width="15"
                :src="
                  require(`@/assets/img/banks/${parseInt(
                    item?.iban.slice(4, 7)
                  )}.svg`)
                "
              />
            </span>
          </template>

          <span>{{ item?.iban_name }}</span>
        </div>
        <div v-if="isEligibleRole && reuseIsMobile" class="d-flex mt-1">
          <v-btn
            dark
            text
            class="btn_small_normal mr-0"
            @click="approve = true"
          >
            تایید
          </v-btn>
          <v-btn
            outlined
            text
            class="btn_small_normal mr-0"
            @click="disApproveTransaction"
          >
            رد کردن
          </v-btn>
        </div>
        <CashInType
          v-if="item?.destination_account_type !== 'VANDAR'"
          :account-type="item?.destination_account_type"
          :account="item?.destination_account"
        />
      </div>
      <v-spacer v-if="!reuseIsMobile" />
      <div v-if="isEligibleRole && !reuseIsMobile" class="d-flex flex-column">
        <v-btn dark text class="btn_small_normal" @click="approve = true">
          تایید
        </v-btn>
        <v-btn
          outlined
          text
          class="btn_small_normal"
          @click="disApproveTransaction"
        >
          رد کردن
        </v-btn>
      </div>
    </div>

    <GeneralDialog
      :active="approve"
      logo="1stCheck.svg"
      content-class="confirmationMessageDialog"
      title="تراکنش را تایید می‌کنید؟"
      description="در صورت تایید، مبلغ تراکنش به کیف پول شما واریز شده و جزییات تراکنش به «لیست واریز و برداشت» اضافه می‌شود."
      btn1-text="بله، تایید می‌کنم"
      btn2-text="منصرف شدم"
      :btn1-loading="loading"
      @btn1Clicked="sendStatus('READY_TO_TRANSFER')"
      @btn2Clicked="closeDialogs()"
    >
      <!--      <template #extra>-->
      <!--        <div class="font-size-12 w-100 mt-4 pr-2 van_color11">-->
      <!--          <v-checkbox-->
      <!--            v-model="save"-->
      <!--            style="width: 100%"-->
      <!--            color="van_green"-->
      <!--            label="این شبا به حساب‌های تایید‌شده مشتری اضافه شود."-->
      <!--          />-->
      <!--        </div>-->
      <!--      </template>-->
    </GeneralDialog>

    <GeneralDialog
      :active="isActiveNotification"
      logo="newIcon/info.svg"
      content-class="confirmationMessageDialog"
      title="اختلال در سرویس واریز شناسه دار"
      :description="alertMessageNotification"
      btn2-text="متوجه شدم"
      @btn2Clicked="closeNotificationModal"
    />

    <GeneralDialog
      :active="disApprove"
      logo="Failed.svg"
      content-class="confirmationMessageDialog"
      title="تراکنش را رد می‌کنید؟"
      :description="feeDescription"
      btn1-text="بله، رد می‌کنم"
      btn1-class="btn_small_normal error"
      btn2-text="خیر، منصرف شدم"
      :btn1-loading="loading"
      @btn1Clicked="sendStatus('READY_TO_REFUND')"
      @btn2Clicked="closeDialogs()"
    />
  </GeneralCard>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard.vue"
import GeneralDialog from "@/components/modals/generalDialog.vue"
import servicesRepository from "@/services/servicesRepository"
import screenSize from "@/mixins/screenSize"
import CashInType from "@/components/businessHome/elements/suspicious/CashInType.vue"
const jmoment = require("moment-jalaali")

export default {
  name: "SuspiciousItem",
  components: { CashInType, GeneralDialog, GeneralCard },
  mixins: [screenSize],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      approve: false,
      disApprove: false,
      save: false,
      loading: false,
      wage: "",
      isActiveNotification: false,
      alertMessageNotification: null,
    }
  },
  computed: {
    transactionAt() {
      return jmoment(this.item.transaction_at).format("HH:mm - jYYYY/jM/jD")
    },
    expireAt() {
      return jmoment(this.item.transaction_at)
        .add(48, "hours")
        .format("HH:mm - jYYYY/jM/jD")
    },
    isEligibleRole() {
      return (
        this.$store.state.business.role === "owner" ||
        this.$store.state.business.role === "admin" ||
        this.$store.state.business.role === "accountant" ||
        this.$store.state.business.role === "operator"
      )
    },
    feeDescription() {
      return `در صورت رد تراکنش، مبلغ واریز شده با کسر ${this.checkWageCurrency(
        this.wage
      )} ${this.currencyName} کامزد، به حساب مبدا بازگردانده می‌شود`
    },
  },
  methods: {
    closeDialogs() {
      this.approve = false
      this.disApprove = false
      this.save = false
      this.loading = false
    },
    sendStatus(status) {
      this.loading = true
      let data = {
        status: status,
      }

      if (status === "READY_TO_TRANSFER") {
        data.save = this.save
      }

      servicesRepository(this)
        .cashIn.httpPostSuspiciousPayment(data, this.item.id)
        .then((response) => {
          this.closeDialogs()

          if (response.data.alert_message === null) {
            this.$store.commit("callSuccessSnackbar", response.data.message)
            this.$emit("removeItem")
          } else {
            this.alertMessageNotification = response.data.alert_message
            this.isActiveNotification = true
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    disApproveTransaction() {
      servicesRepository(this)
        .suspiciousPayment.httpGetSuspiciousPaymentWage(this.item.id)
        .then((response) => {
          this.wage = response.data.data.wage
          this.disApprove = true
        })
    },
    checkWageCurrency(wage) {
      if (this.$store.state.user.currency === "RIAL") return wage
      else if (this.$store.state.user.currency === "TOMAN") return wage / 10
    },
    closeNotificationModal() {
      this.isActiveNotification = false
      this.$emit("removeItem")
    },
  },
}
</script>

<style scoped></style>
