import servicesRepository from "@/services/servicesRepository"
import sortFinancialListOnTop from "@/utils/sortFinancialListOnTop"
import { mapMutations } from "vuex"

const businessCategories = {
  methods: {
    ...mapMutations("newbusiness", ["setBusinessCategories"]),

    reuseGetBusinessCategories() {
      servicesRepository(this)
        .informationService.httpGetCategories()
        .then((response) => {
          this.businessCategoriesList = sortFinancialListOnTop(
            Object.entries(response.data.data)
          )
          this.setBusinessCategories(this.businessCategoriesList)
        })
        .catch(() => {
          window.location.reload()
        })
    },
  },
}
export default businessCategories
