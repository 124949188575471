<template>
  <general-card custom-card-style="padding: 16px !important;">
    <div class="d-flex align-center w-100">
      <div class="font-size-11 w-100">
        <div class="d-flex justify-space-between">
          <div>
            <div class="font-weight-bold">
              <span>
                {{ (rejectedTransaction?.amount / 10) | currency }}
                {{ currencyName }}
              </span>
              |
              <span>شناسه واریز: {{ rejectedTransaction?.cash_in_code }}</span>
              |
              <span>{{ rejectedTransaction?.customer_name }}</span>
            </div>
            <div class="van_color03--text d-flex align-center mt-2">
              <template v-if="rejectedTransaction?.iban">
                <span>{{ rejectedTransaction?.iban }}</span>
                <span class="pr-1 pl-2">
                  <img
                    width="15"
                    :src="
                      require(`@/assets/img/banks/${parseInt(
                        rejectedTransaction?.iban.slice(4, 7)
                      )}.svg`)
                    "
                  />
                </span>
              </template>
              <span>{{ rejectedTransaction?.iban_name }}</span>
            </div>
            <div v-if="reuseIsMobile" class="font-11-no-666-17">
              کارمزد: {{ (rejectedTransaction.fee / 10) | currency }}
              {{ currencyName }}
            </div>
          </div>
          <span v-if="!reuseIsMobile" class="font-11-no-666-17">
            کارمزد: {{ (rejectedTransaction.fee / 10) | currency }}
            {{ currencyName }}
          </span>
        </div>
        <v-divider class="van_color11 my-2" />
        <div
          v-if="rejectedTransaction.amount * 0.1 <= 100000000"
          class="van_color05--text my-2 d-flex"
          :class="
            reuseIsMobile ? 'flex-column' : 'align-center justify-space-between'
          "
        >
          <div class="van_color03--text">
            {{ rejectedTransaction?.description }}
          </div>
          <div :class="{ 'mt-1': reuseIsMobile }">
            <div
              v-if="rejectedTransaction?.status === 'REFUND'"
              class="d-flex align-center justify-space-between"
            >
              <div class="linkBorder van_green--text ml-auto mr-0">
                <a
                  class="text-decoration-none"
                  :href="
                    rejectedTransaction?.refunds[0].settlement_receipt_link
                  "
                  target="_blank"
                >
                  {{ rejectedTransaction?.refunds[0].settlement_receipt_link }}
                </a>
              </div>
              <copy-btn
                class="mr-3"
                :input-id="'item' + transactionIndex"
                :value="rejectedTransaction?.refunds[0].settlement_receipt_link"
              />
            </div>
            <div v-else>
              <v-chip class="ma-0 align-self-end" color="#E5E5E5" label>
                <span class="font-11-no-333-17"> در صف ارسال به بانک </span>
              </v-chip>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="font-11-no-666-17">
            {{ rejectedTransaction?.description }}
          </div>
          <v-expansion-panels class="mb-6">
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-4">
                <span class="font-11-no-666-17"> مشاهده رسید‌ها </span>
                <template v-slot:actions>
                  <v-icon> $expand </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-2">
                <div
                  v-for="(link, linkIndex) in rejectedTransaction?.refunds"
                  :key="linkIndex"
                  class="px-2"
                >
                  <div
                    v-if="link"
                    class="d-flex align-center justify-space-between mb-1"
                  >
                    <div class="linkBorder van_green--text ml-auto mr-0">
                      <a
                        class="text-decoration-none"
                        :href="link.settlement_receipt_link"
                        target="_blank"
                      >
                        {{ link.settlement_receipt_link }}
                      </a>
                    </div>
                    <copy-btn
                      class="mr-3"
                      :input-id="'item' + transactionIndex + linkIndex"
                      :value="link.settlement_receipt_link"
                    />
                  </div>
                </div>
                <div class="all-links-copy-box pa-2">
                  <span class="font-11-no-666-17">
                    برای کپی همه رسید‌ها اینجا کلیک کنید.
                  </span>
                  <copy-btn
                    class="mr-3"
                    :input-id="'item' + transactionIndex"
                    :value="getAllLinksString(rejectedTransaction)"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <CashInType
          v-if="rejectedTransaction?.destination_account_type !== 'VANDAR'"
          :account-type="rejectedTransaction?.destination_account_type"
          :account="rejectedTransaction?.destination_account"
        />
      </div>
    </div>
  </general-card>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard.vue"
import CopyBtn from "@/components/report/elements/show/details/copyBtn.vue"
import screenSize from "@/mixins/screenSize"
import CashInType from "@/components/businessHome/elements/suspicious/CashInType.vue"

export default {
  name: "RejectedItem",
  components: { CopyBtn, GeneralCard, CashInType },
  mixins: [screenSize],
  props: {
    rejectedTransaction: {
      type: Object,
      default: () => {},
    },
    transactionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      save: false,
      loading: false,
    }
  },
  methods: {
    closeDialogs() {
      this.loading = false
    },
    getAllLinksString(item) {
      let arr = []
      item.refunds?.map((link) => arr.push(link.settlement_receipt_link))
      return arr?.join("\n")
    },
  },
}
</script>

<style scoped>
:deep(.v-expansion-panels) {
  z-index: unset !important;
}

.all-links-copy-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-top: 8px;
  background: #ececec;
  border-radius: 4px;
}
</style>
