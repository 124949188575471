import { initialState } from "./state.js"
import business from "./modules/newbusiness"
import { eraseCookie } from "@/utils/cookie"

export const mutations = {
  updateWaitForRefreshToken(state, val) {
    state.waitForRefreshToken = val
  },
  setCurrency(state, variable) {
    state.user.currency = variable
  },
  setLastMobile(state, variable) {
    state.lastMobile = variable
  },
  reset(state) {
    // acquire initial state
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
  setTempEmail(state, val) {
    state.tempEmail = val
  },
  setStartVerificationTab(state, boolean) {
    state.startVerificationTab = boolean
  },
  setStartVerificationBusiness(state, boolean) {
    state.startVerificationBusiness = boolean
  },
  changeVerificationOnboarding(state, index) {
    state.verificationOnboardingIndex = index
  },
  changeUpdateBusinessList(state, boolean) {
    state.updateBusinessList = boolean
  },
  changeBusinessRole(state, role) {
    state.business.role = role
  },
  checkPermission(state, currentPermission) {
    state.business.permission.forEach((element, index) => {
      if (element == currentPermission) {
        return true
      } else {
        return false
      }
    })
  },
  callSecondModal(state, boolean) {
    state.secondShebamodal = boolean
  },
  callAddBusinessIbanIsActive(state, boolean) {
    state.addBusinessIbanIsActive = boolean
  },
  callSecondTelModal(state, boolean) {
    state.secondTelmodal = boolean
  },
  callSecondAddUserModal(state, boolean) {
    state.secondAddUsermodal = boolean
  },
  callAddDocumentmodal(state, boolean) {
    state.secondAddDocumentmodal = boolean
  },
  setShouldDeleteUploadedFile(state, boolean) {
    state.shouldDeleteUploadedFile = boolean
  },
  callUserSetting(state, boolean) {
    state.secondUserSetting = boolean
  },
  callEditFormModal(state, boolean) {
    state.secondEditFormmodal = boolean
  },
  callEditSubFormModal(state, boolean) {
    state.secondEditSubFormmodal = boolean
  },
  callSecondEditCustomerModal(state, boolean) {
    state.secondEditCustomerModal = boolean
  },
  callConfirmarionMessage(state, obj) {
    state.confirmationMessage.status = true
    state.confirmationMessage.title = obj.title
    state.confirmationMessage.description = obj.description
  },
  callErrorMessage(state, obj) {
    state.errorMessage.status = true
    state.errorMessage.title = obj.title
    state.errorMessage.description = obj.description
  },
  callsnackbar(state, text) {
    state.snackbar.status = true
    state.snackbar.message = text
  },
  callSuccessSnackbar(state, text) {
    state.successSnackbar.status = true
    state.successSnackbar.message = text
  },
  closeConfirmarionMessage(state) {
    state.confirmationMessage.status = false
    state.confirmationMessage.title = ""
    state.confirmationMessage.description = ""
  },
  closeErrorMessage(state) {
    state.errorMessage.status = false
    state.errorMessage.title = ""
    state.errorMessage.description = ""
  },
  setUserData(state, object) {
    //this not working
    var objectkey = object.key
    state.user[objectkey] = object.value

    if (object.hasOwnProperty("invitation")) {
      state.user.invitation = object.invitation
    }
    if (object.hasOwnProperty("notification_count")) {
      state.user.notification_count = object.notification_count
    }
  },
  updateStatusBox(state, object) {
    var objectkey = object.key
    state.user.statusBox[objectkey] = object.value
  },
  updateUserMeta(state, object) {
    var objectkey = object.key
    state.user.meta[objectkey] = object.value
  },
  setTempMobile(state, mobile) {
    state.tempMobile = mobile
  },
  setWhatsNew(state, obj) {
    state.whatsNew = obj
  },
  setUser(state, user) {
    state.user = user
    var object = user.statusBox
    state.user.statusBox = object

    var object1 = user.meta
    state.user.meta = object1

    if (user.blocked_amount) state.user.blocked_amount = user.blocked_amount

    // window.CRISP_TOKEN_ID = user.uuid
    // window.$crisp.push(["set", "user:email", [user.uuid + "@vandar.io"]])
    // if (user.name) window.$crisp.push(["set", "user:nickname", [user.name]])
    // window.$crisp.push(["set", "user:phone", [user.mobile]])
    // window.$crisp.push(["do", "session:reset"])
    // const currentHour = new Date(
    //   new Date().toLocaleString("en", { timeZone: "Asia/Tehran" })
    // ).getHours()
    // if (currentHour >= 16 || currentHour < 8) {
    //   window.$crisp.push(["do", "chat:hide"])
    // }
  },
  retrieveToken(state, token) {
    state.token = token
  },
  destroyToken(state) {
    state.token = null
    eraseCookie("access_token")
    eraseCookie("refresh_token")
    // window.CRISP_TOKEN_ID = undefined
    // window.$crisp.push(["do", "session:reset"])
    // const currentHour = new Date(
    //   new Date().toLocaleString("en", { timeZone: "Asia/Tehran" })
    // ).getHours()
    // if (currentHour >= 16 || currentHour < 8) {
    //   window.$crisp.push(["do", "chat:hide"])
    // }
  },
  callsussessAlert(state) {
    state.sussessAlert = true
    var downloadTimer = setTimeout(() => {
      state.sussessAlert = false
    }, 6000)
  },
  isUserOrNot(state, boolean) {
    state.isUser = boolean
  },
  setBusinessName(state, name) {
    //set business name before business api response
    state.isUser = false
    state.business.business_name = name
  },
  setBusiness(state, business) {
    state.business = business
    state.business.statusBox = business.statusBox

    if (business.blocked_amount)
      state.business.blocked_amount = business.blocked_amount
  },
  setIsBusinessLegalOwner(state) {
    state.business.business_owner_is_business_legal_owner = true
    state.business.statusBox.introduction_letter = "accepted"
    state.business.rejects.introduction_letter = null
  },
  changeMpgStatus(state, status) {
    state.business.tools.MPG.status = status
  },
  changeToolsStatus(state, obj) {
    if (state.isUser) {
      state.user.tools[obj.name] = obj.status
    } else {
      state.business.tools[obj.name] = obj.status
    }
  },
  changePendingToolsStatus(state, obj) {
    if (state.isUser) {
      state.user.tools[obj.name].status = obj.status
    } else {
      state.business.tools[obj.name].status = obj.status
    }
  },
  eraseToolsObject(state, obj) {
    if (state.isUser) {
      delete state.user["tools"]
    } else {
      delete state.business["tools"]
    }
  },
  changeTemplate(state, newValue) {
    state.templateStatus = newValue
  },
  eraseWallet(state, name) {
    if (name == "business") {
      //set loading skeleton here
      state.business.wallet = null
    } else if (name == "user") {
      state.user.wallet = null
    }
  },
  changeWallet(state, obj) {
    if (obj.name == "business") {
      state.business.wallet = obj.wallet
    } else if (obj.name == "user") {
      state.user.wallet = obj.wallet
    }
  },
  setBusinessList(state, obj) {
    state.businessList = obj
  },
  updateUserOrBusinessValue(state, obj) {
    if (state.isUser) {
      state.user[obj.key] = obj.value
    } else {
      state.business[obj.key] = obj.value
    }
  },
  updatePostalCodeStatus(state, val) {
    if (state.isUser) {
      state.user.postal_code_detail.postal_code_status = val
    } else {
      state.business.postal_code_detail.postal_code_status = val
    }
  },
  updateTaxCodeStatus(state, val) {
    if (state.isUser) {
      state.user.tax_code_detail.tax_code_status = val
    } else {
      state.business.tax_code_detail.tax_code_status = val
    }
  },
  updateAddressDetail(state, obj) {
    if (state.isUser) {
      state.user.address_detail[obj.key] = obj.value
    } else {
      state.business.address_detail[obj.key] = obj.value
    }
  },
  changeShowSettlementLoading(state, payload) {
    state.showDetailBatchesSettlementLoading = payload
  },
  changeP2pConfirmLoading(state, payload) {
    state.p2pConfirmLoading = payload
  },
  changeLogFilterLoading(state, payload) {
    state.logFilterLoading = payload
  },
  updateNationalId(state, val) {
    state.business.national_id = val
  },
  updateBusinessNameFa(state, val) {
    state.business.business_name_fa = val
  },
  updateLegalBusinessName(state, val) {
    state.business.legal_business_name = val
  },
  changeSandBoxMember(state, val) {
    state.user.sandbox_member = val
  },
  changeIpgStatus(state, obj) {
    state.business.ipg_status = obj
  },
  changeSecondWalletDetailModal(state, val) {
    state.secondWalletDetailModal = val
  },
  changeSecondSettlementModal(state, val) {
    state.secondSettlementModal = val
  },
  changeSecondAutoSettlementModal(state, val) {
    state.secondAutoSettlementModal = val
  },
  changeShowThirdModal(state, val) {
    state.showThirdModal = val
  },
  changeShowSecondModal(state, val) {
    state.showSecondModal = val
  },
  changeUserActions(state, data) {
    let userAction = state.user.actions.find(
      (item) => item.action_name === data.action_name
    )
    if (userAction) {
      userAction.action_time = data.action_time
    } else {
      state.user.actions.push(data)
    }
  },
  setTokenOnStore(state, token) {
    state.token = token
  },
  setSignatureHasLoaded(state, value) {
    state.signatureHasLoaded = value
  },

  changeCashInCode(state, payload) {
    state.business.cash_in_code = payload
  },
  changeShowWhatsNewsDialog(state, payload) {
    state.showWhatsNewsDialog = payload
  },
  changeMiandoFilterLoading(state, payload) {
    state.miandoFilterLoading = payload
  },
  updateCashInIbans(state, data) {
    state.business.cashInIbans = data
  },
  changeSuspiciousCount(state, count) {
    state.suspiciousCount = count
  },
  changePicSuspicious(state, payload) {
    state.business.pic_suspicious_check = payload
  },
  callTheGetSuspiciousRefund(state, payload) {
    state.shouldGetSuspiciousRefund = payload
  },
  changeSuspiciousOnTheWayCount(state, count) {
    state.suspiciousOnTheWayCount = count
  },
  checkIsRoutedFromSuspiciousItemsModal(state, payload) {
    state.isRoutedFromSuspiciousItemsModal = payload
  },
  callTheGetSuspiciousRefundOnTheWay(state, payload) {
    state.shouldGetSuspiciousRefundOnTheWay = payload
  },
  changeTheStateOfShouldFilterWithCashInByCode(state, payload) {
    state.shouldFilterWithCashInByCode = payload
  },
  changeNationalIdFocusState(state, payload) {
    state.isNationalIdFocused = payload
  },
  updateCategoryId(state, category_id) {
    state.business.category_id = category_id
  },
  setAccountsList(state, payload) {
    let totalBalance = 0
    for (let i = 0; i < payload.length; i++) {
      payload[i].loading = false
      if (payload[i].balance) totalBalance += payload[i].balance
    }

    state.accountsList = payload
    state.totalBalanceAccounts = totalBalance
  },
  setTheMostUpdatedTime(state, payload) {
    state.accountLastUpdatedTime = payload
  },
  changeAccountDetailsSecondModal(state, payload) {
    state.accountDetailsSecondModal = payload
  },
  changeAccountDetailsThirdModal(state, payload) {
    state.accountDetailsThirdModal = payload
  },
  setWalletAmountDetails(state, payload) {
    state.walletAmountDetails = payload
  },
  setShowStateOfDatePicker(state, showStateOfDatePicker) {
    state.isShowDatePicker = showStateOfDatePicker
  },
}
