<template>
  <div>
    <v-layout
      :align-center="!$vuetify.breakpoint.xsOnly"
      :column="$vuetify.breakpoint.xsOnly"
      justify-space-between
    >
      <v-flex>
        <v-layout align-start justify-space-between>
          <v-flex>
            <h2 class="mr-0 ml-auto">واریز و برداشت</h2>
          </v-flex>
          <v-spacer />
          <v-flex v-if="$vuetify.breakpoint.xsOnly" class="text-left">
            <v-btn
              :loading="downloadLoading"
              class="btn_small_normal white_btn ml-0 mt-0"
              dark
              depressed
              outlined
              text
              @click="openDownloadModal()"
            >
              <template v-if="haveInitDownload">
                <v-progress-circular
                  :class="!$vuetify.breakpoint.xsOnly ? 'ml-2' : ''"
                  :size="20"
                  :width="2"
                  color="van_color03"
                  indeterminate
                />
              </template>
              <template v-else>
                <v-img
                  :class="!$vuetify.breakpoint.xsOnly ? 'ml-2' : ''"
                  :src="require('@/assets/img/download-outline.svg')"
                  max-width="13px"
                />
              </template>
              <span v-if="!$vuetify.breakpoint.xsOnly"> خروجی </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-spacer />
      <div>
        <v-layout align-start justify-space-between>
          <v-flex v-if="$vuetify.breakpoint.smAndUp">
            <v-btn
              :loading="downloadLoading"
              class="btn_small_normal white_btn mt-0"
              dark
              depressed
              outlined
              text
              @click="openDownloadModal()"
            >
              <template v-if="haveInitDownload">
                <v-progress-circular
                  :class="!$vuetify.breakpoint.xsOnly ? 'ml-2' : ''"
                  :size="20"
                  :width="2"
                  color="van_color03"
                  indeterminate
                />
              </template>
              <template v-else>
                <v-img
                  :class="!$vuetify.breakpoint.xsOnly ? 'ml-2' : ''"
                  :src="require('@/assets/img/download-outline.svg')"
                  max-width="13px"
                />
              </template>
              <span v-if="!$vuetify.breakpoint.xsOnly"> خروجی </span>
            </v-btn>
          </v-flex>
          <v-flex class="search-input-with-type backWhite">
            <v-text-field
              ref="searchInput"
              v-model="searchQuery"
              :prepend-inner-icon="searchQuery != null ? 'close' : 'search'"
              height="36"
              flat
              hide-details
              outlined
              solo
              placeholder="جستجو"
              class="search-query drop-down mt-0"
              @keydown.enter="enterHandler($event)"
              @click:prepend-inner="clearSearch()"
              @focusin="showSearchDropDown = true"
              @blur="checkForCloseDropDown"
            >
              <template #message="message">
                <div v-html="message.message" />
              </template>
            </v-text-field>
            <v-divider vertical />
            <v-select
              v-model="selectedSearchType"
              label="انتخاب کنید"
              :items="searchTypes"
              item-text="text"
              item-value="value"
              class="select-type mt-0"
              height="36"
              flat
              hide-details
              outlined
              solo
              :menu-props="{ offsetY: true }"
              disabled
            >
            </v-select>
            <v-btn
              :disabled="disableSearch"
              class="btn-search-inner"
              text
              @click="putFilterAndGetSums()"
            >
              <v-icon class="white--text" size="20"> chevron_left </v-icon>
            </v-btn>
            <div v-if="showSearchDropDown" class="drop-down-search">
              <div
                class="d-flex align-center justify-center text-center van_color11 van_color04--text pa-5 drop-down ma-1 radius-4"
              >
                برای مشاهده نتایج، عبارت مورد نظرتان را وارد کرده و دسته‌بندی را
                انتخاب کنید.
              </div>
              <v-list-item-group
                v-model="selectedSearchTypeIndex"
                class="select-menu"
              >
                <v-list-item
                  v-for="typeElement in searchTypes"
                  :key="typeElement.value"
                >
                  <v-list-item-content class="drop-down">
                    <v-list-item-title class="font_2 drop-down">
                      {{ typeElement.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </v-layout>

    <TableFilter @putFilter="putFilterAndGetSums()"></TableFilter>

    <div v-if="$store.getters.isFilterApplied || isSearchApplied">
      <v-layout
        v-if="!$store.state.report.loading && sumLoaded && metaLoaded"
        :align-center="!$vuetify.breakpoint.smAndDown"
        :align-start="$vuetify.breakpoint.smAndDown"
        :class="setFilterBarColor"
        :style="
          $vuetify.breakpoint.smAndDown
            ? total == 0
              ? 'flex-direction:row; flex-wrap: wrap;'
              : 'flex-direction:column'
            : 'align-items: center;'
        "
        justify-center
      >
        <v-flex
          v-if="transactionSum == 0 && settlementSum == 0 && total == 0"
          class="font_2 py-1 px-5"
        >
          <span
            v-if="
              $store.getters.isFilterApplied ||
              (isSearchApplied && $store.getters.isFilterApplied)
            "
          >
            {{ lang.general.noTransactionsWithThisFilter }}
          </span>
          <span v-else-if="isSearchApplied">
            {{ lang.general.noTransactionsWithThisSearch }}
          </span>
        </v-flex>
        <v-layout
          v-else
          :column="$vuetify.breakpoint.smAndDown"
          :style="$vuetify.breakpoint.smAndDown ? 'width:100%' : ''"
          align-center
          justify-center
        >
          <v-flex class="filterTransaction">
            <span class="font_12">{{ total | thousandSeprator }}</span>
            <span class="font_2 mr-2">تراکنش فیلتر شده</span>
          </v-flex>
          <v-flex
            v-if="transactionSum == 0 && settlementSum == 0"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'padding: 10px 20px;width:100%'
                : 'padding: 5px 20px'
            "
            class="font_2"
          >
            این تراکنش‌ها تاثیری در مانده کیف‌پول شما نداشتند
          </v-flex>
          <v-layout
            v-else
            :column="$vuetify.breakpoint.smAndDown"
            :style="$vuetify.breakpoint.smAndDown ? 'width:100%' : ''"
            wrap
          >
            <v-flex v-if="transactionSum != '0'" class="filterTransaction">
              <span class="font_12">{{ transactionSum | currency }}</span>
              <span class="font_2 mr-2">{{ currencyName }} واریز</span>
            </v-flex>
            <v-flex
              v-if="settlementSum != '0'"
              class="filterTransaction withoutBorder"
            >
              <span class="font_12">{{ settlementSum | currency }}</span>
              <span class="font_2 mr-2">{{ currencyName }} برداشت</span>
            </v-flex>
          </v-layout>
        </v-layout>

        <v-flex
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'display:flex;margin-right:auto;'
              : 'display:flex;'
          "
          align-end
          class="btns"
          justify-end
        >
          <v-btn
            v-if="isSearchApplied"
            class="btn_small_normal ml-1"
            dark
            depressed
            outlined
            style="font-size: 10px !important; padding: 0 10px"
            text
            @click="clearSearch()"
          >
            <v-icon class="ml-2" color="van_color03" size="15"> close </v-icon>
            {{ lang.general.removeSearch }}
          </v-btn>

          <v-btn
            v-if="$store.getters.isFilterApplied"
            class="btn_small_normal mr-1"
            dark
            depressed
            outlined
            style="font-size: 10px !important; padding: 0 10px"
            text
            @click="$store.dispatch('deleteAllReportFilters', false)"
          >
            <v-icon class="ml-2" color="van_color03" size="15"> close </v-icon>
            {{ lang.general.removeFilters }}
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout
        v-else
        class="greenRow"
        style="background-color: #eee; height: 80px"
      />
    </div>

    <div style="position: relative">
      <!-- table skeleton -->
      <v-data-table
        v-if="desserts[0] == 'skeleton'"
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'elevation-1 vandarTable smAndDownTable'
            : 'elevation-1 vandarTable reportTable'
        "
        :headers="headers"
        :hide-default-header="totalDesserts !== 0 ? false : true"
        :items="desserts"
        :items-per-page="rows"
        :options.sync="pagination"
        :server-items-length="totalDesserts"
        hide-default-footer
        items-per-page-text="نمایش در هر صفحه "
        no-data-text="تراکنشی برای نمایش وجود ندارد"
      >
        <template #item>
          <tableRowSkeleton />
        </template>
        <template #no-data>
          <v-layout align-center justify-center wrap>
            <v-flex class="vandar-table-empty">
              تراکنشی برای نمایش وجود ندارد
            </v-flex>
          </v-layout>
        </template>
      </v-data-table>

      <!-- filled table -->
      <v-data-table
        v-if="desserts != null && desserts[0] != 'skeleton'"
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'elevation-1 vandarTable smAndDownTable'
            : 'elevation-1 vandarTable'
        "
        :headers="headers"
        :hide-default-header="totalDesserts !== 0 ? false : true"
        :items="desserts"
        :items-per-page-items="rows"
        :options.sync="pagination"
        :server-items-length="totalDesserts"
        hide-default-footer
        items-per-page-text="نمایش در هر صفحه "
        no-data-text="تراکنشی برای نمایش وجود ندارد"
      >
        <template #item="{ item }">
          <tableRow :transaction="item" @showDetail="show_detail" />
        </template>
        <!-- <template v-slot:no-data>
          <v-layout row wrap justify-center align-center>
            <v-flex
              style="text-align: center; padding: 88px 10px; cursor: default"
              >تراکنشی برای نمایش وجود ندارد</v-flex
            >
          </v-layout>
        </template> -->
      </v-data-table>
      <v-layout
        v-else-if="
          desserts[0] != 'skeleton' &&
          total == 0 &&
          !$store.getters.isFilterApplied
        "
        align-center
        justify-center
        style="background-color: white; font-weight: normal"
        wrap
      >
        <v-flex class="vandar-table-empty">
          تراکنشی برای نمایش وجود ندارد
        </v-flex>
      </v-layout>
      <TablePaginate
        v-if="totalDesserts > 20"
        :items-length="total"
        :total-pages="totalPages"
        @page="changePage"
        @rowsPerPage="changeItemsPerPage"
      />
    </div>

    <!-- modals -->
    <v-dialog
      v-model="status"
      :content-class="
        showAddRefund || showRevised || showFormDetails || showMoreDetails
          ? 'secondModal vanmodal overflowHide'
          : 'vanmodal overflowHide'
      "
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      transition="slide-x-transition"
    >
      <!--      show is here-->
      <show
        v-if="showStatus"
        :item="selectedForm"
        @closeModal="close_modal"
        @openFormDetail="openFormDetailsModal($event)"
        @showAddRefund="openAddRefund()"
        @showRevisedTransaction="showRevisedTransaction"
        @moreDetailsClick="showMoreDetails = true"
      />
    </v-dialog>

    <!-- modals -->
    <v-dialog
      v-model="showFormDetails"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="vanmodal "
      no-click-animation
      transition="slide-x-transition"
    >
      <show-form
        v-if="showFormDetails"
        :id="formId"
        :from-reports="true"
        :second-modal="true"
        @closeModal="showFormDetails = false"
      />
    </v-dialog>

    <v-dialog
      v-model="downloadStatus"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="left-modal"
      no-click-animation
      transition="slide-x-transition"
    >
      <downloadManager
        v-if="downloadStatus"
        :page="pagination.page"
        :rows-per-page="pagination.itemsPerPage"
        :search="searchQuery"
        :selected-search-type="selectedSearchType"
        @closeModal="downloadStatus = false"
        @exportsChange="getLastExports()"
      />
    </v-dialog>
    <v-dialog
      v-if="refundCanCall"
      v-model="showAddRefund"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="vanmodal "
      no-click-animation
      transition="slide-x-transition"
    >
      <!--    show refund form-->
      <AddRefund
        v-show="showAddRefund"
        :item="selectedForm"
        :second-modal="true"
        @closeModal="close_modal"
      />
    </v-dialog>

    <!-- show revised transaction -->
    <v-dialog
      v-model="showRevised"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="vanmodal"
      no-click-animation
      open-delay="0"
      transition="slide-x-transition"
    >
      <show
        :item="reivsedTransactionSelected"
        :secound-modal="true"
        @closeSecoundModal="showRevised = false"
      />
    </v-dialog>

    <v-dialog
      v-model="showMoreDetails"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <shaparak-details @closeModal="showMoreDetails = false" />
    </v-dialog>
  </div>
</template>
<script>
import show from "../modals/show.vue"
import tableRowSkeleton from "../elements/tableRowSkeleton.vue"
import tableRow from "../elements/tableRow.vue"
import TableFilter from "./tableFilter.vue"
import { mapState } from "vuex"
import downloadManager from "../modals/downloadManager.vue"
import AddRefund from "@/components/report/modals/addRefund"
import TablePaginate from "../../elements/tablePaginate"
import socket from "../../../socket"
import showForm from "../../requestMoney/modals/show"
import ShaparakDetails from "@/components/report/modals/shaparakDetails.vue"
import lang from "@/lang/languageResource.json"

export default {
  components: {
    ShaparakDetails,
    TablePaginate,
    show,
    tableRowSkeleton,
    tableRow,
    TableFilter,
    downloadManager,
    AddRefund,
    showForm,
  },
  props: {
    update: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      exports: [],
      fUrl: "",
      showStatus: false,
      downloadStatus: false,
      status: false,
      totalDesserts: 1,
      desserts: [],
      loading: true,
      pagination: {
        descending: false,
        page: 1,
        itemsPerPage: 20,
        // sortBy: null,
        itemsLength: 0,
      },
      totalPages: 0,
      rows: ["20", "50", "100"],
      selectedForm: {},
      downloadLoading: false,
      //search data start
      searchQuery: null,
      searchTypes: [
        { text: "اطلاعات تراکنش", value: "description" },
        { text: "مبدا تراکنش", value: "source_account" },
        { text: "کد مرجع", value: "ref_id" },
        { text: "کد رهگیری بانکی", value: "tracking_code" },
        { text: "شناسه یکتا", value: "transaction_id" },
        { text: "شماره فاکتور", value: "factor_number" },
        { text: "شماره شبا", value: "iban" },
        { text: "دریافت کننده", value: "iban_owner_name" },
        { text: "موبایل", value: "mobile_number" },
        { text: "توکن", value: "api_token" },
        { text: "شماره کارت", value: "card_number" },
        { text: "شناسه فرم", value: "form_id" },
        { text: "شناسه واریز", value: "payment_number" },
        { text: "تسویه شاپرک", value: "shaparak_settlement_id" },
      ],
      selectedSearchType: null,
      selectedSearchTypeIndex: null,
      //search data end

      transactionSum: 0,
      settlementSum: 0,
      total: 0,
      apiCallLoading: false,
      isMOuntHook: true,
      metaLoaded: false,
      sumLoaded: false,
      timeOut: null,
      formId: null,
      disableSearch: true,
      showAddRefund: false,
      showFormDetails: false,
      showRevised: false,
      reivsedTransactionSelected: null,
      showSearchDropDown: false,
      showMoreDetails: false,
      lang,
      isSearchApplied: false,
    }
  },
  computed: {
    haveInitDownload() {
      if (!this.exports || this.exports.length === 0) return false
      let itemWithInit = this.exports.filter((item) => item.status === "INIT")
      return itemWithInit.length > 0
    },
    refundCanCall() {
      if (
        this.selectedForm &&
        this.selectedForm.hasOwnProperty("id") &&
        this.$store.state.business.hasOwnProperty("tools") &&
        this.$store.state.business.tools.refund.status == "ACTIVE" &&
        this.selectedForm.status == 1 &&
        (this.selectedForm.channel == "فرم دریافت پول" ||
          this.selectedForm.channel == "پرداخت اینترنتی")
      ) {
        return true
      } else {
        return false
      }
    },
    setFilterBarColor() {
      if (this.total == "0") return "grayRow"

      switch (this.statusFilters) {
        case "succeed":
          return "greenRow"
        case "failed":
        case "revised":
        case "canceled":
          return "redRow"
        case "pending":
          return "yellowRow"
      }
      return "grayRow"
    },
    headers() {
      return [
        {
          text: " ",
          align: "right",
          sortable: false,
          value: "status",
        },
        {
          text: "وضعیت",
          align: "right",
          sortable: false,
          value: "status",
        },
        {
          text: "آخرین به‌روزرسانی",
          align: "right",
          sortable: false,
          value: "effective_at_jalali",
        },
        {
          text: "اطلاعات تراکنش",
          align: "right",
          sortable: false,
          value: "payer",
        },
        {
          text: "مبلغ (" + this.currencyName + ")",
          align: "right",
          sortable: false,
          value: "amount",
        },
        {
          text: "مانده کیف پول (" + this.currencyName + ")",
          align: "right",
          sortable: false,
          value: "wallet",
        },
      ]
    },
    ...mapState({
      kindFilter: (state) => state.report.kindFilter,
      statusFilters: (state) => state.report.statusFilters,
      channelFilter: (state) => state.report.channelFilter,
      fromDate: (state) => state.report.fromDate,
      toDate: (state) => state.report.toDate,
      duration: (state) => state.report.duration,
    }),
  },
  watch: {
    status: {
      handler: function (newValue) {
        if (!newValue) {
          this.close_modal()
        }
      },
    },
    showRevised: {
      handler: function (newValue) {
        if (newValue) {
          this.reivsedTransactionSelected = null
        }
      },
    },
    desserts: {
      handler() {
        if (
          this.desserts &&
          this.desserts.length == 0 &&
          (this.$store.state.report.loading == true ||
            this.apiCallLoading == true)
        ) {
          for (let index = 0; index < this.pagination.itemsPerPage; index++) {
            this.desserts[index] = "skeleton"
          }
        }
      },
      immediate: true,
    },
    update: function (newValue, oldValue) {
      if (newValue == true) {
        this.loading = true
        this.desserts = []
        this.getDataFromApi()
      }
    },
    searchQuery: function (newValue, oldValue) {
      if (newValue && newValue.length > 2 && this.selectedSearchType) {
        this.disableSearch = false
      } else {
        this.disableSearch = true
      }
      if (!newValue && oldValue != null) {
        this.putFilterAndGetSums()
      }
    },
    selectedSearchType: function (newValue) {
      if (newValue && this.searchQuery && this.searchQuery.length > 2) {
        this.disableSearch = false
      } else {
        this.disableSearch = true
      }
    },
    selectedSearchTypeIndex: function (newValue) {
      if (newValue || newValue === 0) {
        this.selectedSearchType = this.searchTypes[newValue].value
        if (this.searchQuery) this.showSearchDropDown = false
      } else {
        this.selectedSearchType = null
      }
    },
  },
  mounted() {
    this.getLastExports()
    if (
      this.$route.params.filtered === "successfullTransactionRequestMoney" &&
      this.$route.params.formId !== ""
    ) {
      this.selectedSearchTypeIndex = 11
      this.selectedSearchType = "form_id"
      this.searchQuery = this.$route.params.formId
    }
  },
  created() {
    socket.on("export_excel", (payload) => {
      this.changeExports(payload)
    })
  },
  methods: {
    changeExports(payload) {
      let current = this.exports.find((item) => item.uuid == payload.data.uuid)
      if (current) {
        let index = this.exports.indexOf(current)
        this.exports[index] = payload.data
        const tempExport = this.exports
        this.exports = []
        this.exports = tempExport
      }
    },
    getLastExports() {
      this.getListLoading = true
      this.$http
        .get(this.$store.getters.apiUrlParam + "/transaction/report-filter", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + this.$store.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.exports = response.data.data
          this.getListLoading = false
        })
        .catch((error) => {
          this.getListLoading = false
          //callsnackbar
        })
    },
    enterHandler(event) {
      if (
        event.key == "Enter" &&
        this.searchQuery &&
        this.searchQuery.length > 2
      ) {
        this.putFilterAndGetSums()
      }
    },
    openDownloadModal() {
      this.close_modal()
      this.downloadStatus = true
    },
    show_detail(item) {
      this.close_modal()
      this.selectedForm = item
      this.$store.commit(
        "setAggregateTransactionData",
        item.aggregate_transaction
      )
      this.$store.commit(
        "setTransactionIsWalletAffected",
        item.is_wallet_affected
      )
      this.showStatus = true
      this.status = true
    },
    close_modal(obj) {
      this.status = false
      this.showStatus = false
      this.selectedForm = {}
      this.downloadStatus = false
      this.showAddRefund = false
      this.showRevised = false
      this.showMoreDetails = false
      if (obj && obj.status) {
        this.$emit("updateSettelment")
      }
      if (obj?.searchParams) {
        this.selectedSearchType = "shaparak_settlement_id"
        this.searchQuery = obj.searchParams.id
        this.$store.dispatch("deleteAllReportFilters", false)
        this.$store.commit("updateChannelFilter", [obj.searchParams.type])
      }
    },
    putFilterAndGetSums() {
      if (
        this.searchQuery &&
        this.searchQuery?.toString().length > 2 &&
        this.selectedSearchType
      ) {
        this.isSearchApplied = true
      }
      this.showSearchDropDown = false
      // this.$store.commit('updateLoading', true)
      this.resetPage()
      this.getDataFromApi(true)
    },
    getDataFromApi(sumNeeded) {
      this.apiCallLoading = true
      this.desserts = []
      //this.totalDesserts = 1
      this.isMOuntHook = false
      return new Promise((resolve, reject) => {
        const { descending, page, itemsPerPage, sortBy, itemsLength } =
          this.pagination
        var url

        url = "?page=" + page + "&per_page=" + itemsPerPage

        if (this.kindFilter != "0") {
          url = url + "&statusKind=" + this.kindFilter
        }
        if (this.statusFilters != "0") {
          url = url + "&status=" + this.statusFilters
        }

        //add channel
        var channelF = "&channel="
        if (this.channelFilter != null) {
          this.channelFilter.forEach((element, index) => {
            channelF = channelF + element
            if (index != this.channelFilter.length - 1)
              channelF = channelF + ","
          })
          url = url + channelF
        }

        //add duration
        if (this.duration != "0" && this.duration != "5") {
          url = url + "&duration=" + this.duration
        }

        //add date
        if (this.fromDate !== null) {
          url = url + "&fromDate=" + this.fromDate
        }
        if (this.toDate !== null) {
          url = url + "&toDate=" + this.toDate
        }

        //add search query
        if (this.searchQuery != null && this.selectedSearchType) {
          url =
            url +
            `&q=${this.searchQuery}&search_field=${this.selectedSearchType}`
        }

        this.fUrl = url
        this.getTransaction(url)
        this.getMata(url)
        if (sumNeeded) {
          this.getTransactionSum(url)
        }
      })
    },
    getTransaction(url) {
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") + "/transaction" + url,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$store.commit("updateLoading", false)
          this.apiCallLoading = false
          if (response.data !== null) {
            let items = response.data.data

            //add rowNumber to the array
            items.forEach((element, index) => {
              element.rowNumber =
                index +
                (this.pagination.page - 1) * this.pagination.itemsPerPage +
                1
            })
            this.desserts = items
          }
          this.$emit("doneUpdateTable")
          resolve({})
        })
        .catch((error) => {
          this.$store.commit("updateLoading", false)
          this.apiCallLoading = false
          //callsnackbar
        })
    },
    getMata(url) {
      this.metaLoaded = false
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") +
            "/transaction/meta" +
            url,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.data !== null) {
            var total = response.data.data.total
            this.total = total
            this.totalDesserts = total
            this.metaLoaded = true
            this.totalPages = response.data.data.pages
          }
          resolve({})
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    getTransactionSum(url) {
      this.sumLoaded = false
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") +
            "/transaction/sum" +
            url,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.data !== null) {
            this.transactionSum = response.data.data.transaction_sum
            this.settlementSum = response.data.data.settlement_sum

            this.sumLoaded = true
          }
          resolve({})
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    openAddRefund() {
      this.showAddRefund = true
    },
    openFormDetailsModal(event) {
      this.formId = event
      this.showFormDetails = true
    },
    showRevisedTransaction(revisedTransactionId) {
      this.showRevised = true

      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") +
            "/transaction?id=" +
            revisedTransactionId,
          {
            timeout: 0,
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.reivsedTransactionSelected = response.data.data[0]
            // this.showRevised = true
          }
        })
        .catch((error) => {
          //callsnackbar
        })
    },
    clearSearch() {
      this.searchQuery = null
      this.selectedSearchType = null
      this.selectedSearchTypeIndex = null
      this.isSearchApplied = false
    },
    checkForCloseDropDown() {
      window.onclick = function (e) {
        let classList = e.target.classList
        if (
          (classList && classList.contains("drop-down")) ||
          this.$refs.searchInput?.isFocused
        )
          return
        this.showSearchDropDown = false
      }.bind(this)
    },
    changeItemsPerPage(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getDataFromApi(false)
    },
    changePage(newPage) {
      this.pagination.page = newPage
      this.getDataFromApi(false)
    },
    resetPage() {
      if (this.pagination.page !== 1) this.pagination.page = 1
    },
  },
}
</script>
