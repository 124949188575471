<template>
  <GeneralCard custom-card-style="padding: 16px !important;">
    <div
      class="d-flex"
      :class="
        reuseIsMobile ? 'flex-column' : 'align-center justify-space-between'
      "
    >
      <div class="font-size-11">
        <div class="font-weight-bold">
          <span> {{ (item?.amount / 10) | currency }} {{ currencyName }} </span>
          |
          <span>شناسه واریز: {{ item?.cash_in_code }}</span>
          |
          <span>{{ item?.customer_name }}</span>
        </div>
        <div class="van_color03--text my-2">
          <span>تاریخ ایجاد: {{ transactionAt }}</span>
        </div>
        <v-divider
          v-if="item.status !== 'FILLING_SOURCE_IBAN'"
          class="van_color11 mb-2"
        />
        <div
          v-if="item.status !== 'FILLING_SOURCE_IBAN'"
          class="d-flex justify-space-between"
        >
          <div class="van_color03--text d-flex">
            <template v-if="item?.iban">
              <span>{{ item?.iban }}</span>
              <span class="pr-1 pl-2">
                <img
                  width="15"
                  :src="
                    require(`@/assets/img/banks/${parseInt(
                      item?.iban.slice(4, 7)
                    )}.svg`)
                  "
                />
              </span>
            </template>

            <span>{{ item?.iban_name }}</span>
          </div>
        </div>
      </div>
      <v-chip
        class="ma-0"
        :class="reuseIsMobile ? 'align-self-start' : 'align-self-end'"
        :color="generateStatus().color"
        label
      >
        <span class="font-11-no-333-17">
          {{ generateStatus().text }}
        </span>
      </v-chip>
    </div>

    <CashInType
      v-if="item?.destination_account_type !== 'VANDAR'"
      :account-type="item?.destination_account_type"
      :account="item?.destination_account"
    />
  </GeneralCard>
</template>

<script>
import GeneralCard from "@/components/elements/generalCard.vue"
import screenSize from "@/mixins/screenSize"
import CashInType from "@/components/businessHome/elements/suspicious/CashInType.vue"
const jmoment = require("moment-jalaali")

export default {
  name: "SuspiciousOnTheWayItem",
  components: { GeneralCard, CashInType },
  mixins: [screenSize],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      wage: "",
    }
  },
  computed: {
    transactionAt() {
      return jmoment(this.item.transaction_at).format("HH:mm - jYYYY/jM/jD")
    },
    expireAt() {
      return jmoment(this.item.transaction_at)
        .add(48, "hours")
        .format("HH:mm - jYYYY/jM/jD")
    },
    feeDescription() {
      return `در صورت رد تراکنش، مبلغ واریز شده با کسر ${this.checkWageCurrency(
        this.wage
      )} ${this.currencyName} کامزد، به حساب مبدا بازگردانده می‌شود`
    },
  },
  methods: {
    checkWageCurrency(wage) {
      if (this.$store.state.user.currency === "RIAL") return wage
      else if (this.$store.state.user.currency === "TOMAN") return wage / 10
    },
    generateStatus() {
      if (this.item.status === "READY_TO_TRANSFER") {
        return {
          text: "در انتظار واریز به کیف‌پول",
          color: "#dbece7",
        }
      } else if (this.item.status === "FILLING_SOURCE_IBAN") {
        return {
          text: "در انتظار شناسایی شبا مبدا",
          color: "#e5e5e5",
        }
      }
    },
  },
}
</script>

<style scoped></style>
