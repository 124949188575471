const business = {
  namespaced: true,
  state() {
    return {
      //$store.state.form.id
      isHaghighi: false,
      business_type: null,
      id: null,
      legal_business_name: "",
      business_name: "",
      business_name_fa: "",
      category_id: "",
      city_id: "",
      national_id: null,
      phone_number: null,
      postal_code: null,
      address: null,
      avatar: null,
      national_card_photo: null,
      official_Newspaper: null,
      statusBox: {
        payment_required: false,
        document: false,
      },
      status: 1,
      cancelConfirmation: false,
      payementRequire: false,
      isPostalCodeFieldFocused: false,
      isPhoneNumberFieldFocused: false,
      isOnboardingFirstBtnActive: false,
      businessCategoriesList: [],
      categoryFieldFocusState: false,
      websiteUrlFieldFocusState: false,
      taxCodeFieldFocusState: false,
      ipsFieldFocusState: false,
      wageFieldShowingState: false,
      shebaFieldFocusState: false,
      uploadFieldFocusState: false,
      shouldCreateIpg: false,
      isOnboardingThirdStepDone: false,
      isOnboardingFourthStepDone: false,
      isShowBoxUnderShebaField: false,
      //today_transactions: 0,
      //today_settlements: 0,
      //wallet: 0,
    }
  },
  mutations: {
    changeStatus(state, boolean) {
      state.isHaghighi = boolean
    },
    addBusiness(state, obj) {
      state.isHaghighi == true
        ? (state.business_type = "personal")
        : (state.business_type = "legal")
      state.business_name = obj.business_name
      state.business_name_fa = obj.business_name_fa
      state.phone_number = obj.phone_number
      state.postal_code = obj.postal_code
      state.address = obj.address
      state.category_id = obj.category_id
      state.city_id = obj.city_id
      if (obj.hasOwnProperty("avatar")) state.avatar = obj.avatar
      if (obj.hasOwnProperty("national_card_photo"))
        state.national_card_photo = obj.national_card_photo
      if (obj.hasOwnProperty("official_Newspaper"))
        state.official_Newspaper = obj.official_Newspaper
      if (state.isHaghighi == false) {
        state.legal_business_name = obj.legal_business_name
        state.national_id = obj.national_id
      }
    },
    uploadFile(state, obj) {
      var objKey = Object.keys(obj)[0]
      state[objKey] = obj[objKey]
    },
    changeCancelConfirmation(state, boolean) {
      state.cancelConfirmation = boolean
    },
    truePayementRequire(state) {
      state.payementRequire = true
    },
    changeBusinessKey(state, data) {
      state[data.key] = data.value
    },
    changePostalCodeFieldFocusStatus(state, focusState) {
      state.isPostalCodeFieldFocused = focusState
    },
    changePhoneNumberFieldFocusStatus(state, focusState) {
      state.isPhoneNumberFieldFocused = focusState
    },
    changeOnboardingFirstBtnActiveState(state, btnActiveState) {
      state.isOnboardingFirstBtnActive = btnActiveState
    },
    setBusinessCategories(state, businessCategories) {
      state.businessCategoriesList = businessCategories
    },
    changeCategoryFieldFocusState(state, categoryFieldFocusState) {
      state.categoryFieldFocusState = categoryFieldFocusState
    },
    changeWebsiteUrlFieldFocusState(state, websiteUrlFieldFocusState) {
      state.websiteUrlFieldFocusState = websiteUrlFieldFocusState
    },
    changeTaxCodeFieldFocusState(state, taxCodeFieldFocusState) {
      state.taxCodeFieldFocusState = taxCodeFieldFocusState
    },
    changeIpsFieldFocusState(state, ipsFieldFocusState) {
      state.ipsFieldFocusState = ipsFieldFocusState
    },
    changeWageFieldShowingState(state, wageFieldShowingState) {
      state.wageFieldShowingState = wageFieldShowingState
    },
    changeShebaFieldFocusState(state, shebaFieldFocusState) {
      state.shebaFieldFocusState = shebaFieldFocusState
    },
    changeUploadInputFocusState(state, uploadFieldFocusState) {
      state.uploadFieldFocusState = uploadFieldFocusState
    },
    changeCreatingIpgStatus(state, shouldCreateIpg) {
      state.shouldCreateIpg = shouldCreateIpg
    },
    changeOnboardingThirdStepDoneStatus(state, isOnboardingThirdStepDone) {
      state.isOnboardingThirdStepDone = isOnboardingThirdStepDone
    },
    changeOnboardingFourthStepDoneStatus(state, isOnboardingFourthStepDone) {
      state.isOnboardingFourthStepDone = isOnboardingFourthStepDone
    },
    changeShowingBoxUnderShebaFieldState(state, isShowBoxUnderShebaField) {
      state.isShowBoxUnderShebaField = isShowBoxUnderShebaField
    },
  },
  getters: {},
}

export default business
