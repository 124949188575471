<template>
  <div class="mt-2">
    <v-divider />
    <v-chip-group :column="reuseIsMobile">
      <v-chip color="#4c9f87" class="mr-0">
        <span class="font-12-no-333-20">
          واریز شناسه‌دار به حساب:
          {{
            accountType === "VANDAR" ? persianVandarName : persianBusinessName
          }}
        </span>
      </v-chip>
      <v-chip color="#4c9f87" :class="{ 'mr-0': reuseIsMobile }">
        <span class="font-12-no-333-20">
          شماره حساب:
          {{ account }}
        </span>
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
import screenSize from "@/mixins/screenSize"
export default {
  name: "CashinType",
  mixins: [screenSize],
  props: {
    accountType: {
      type: String,
      default: "VANDAR",
    },
    account: {
      type: String,
      default: "",
    },
  },
  computed: {
    persianVandarName() {
      return "وندار"
    },
    persianBusinessName() {
      return "کسب و کار"
    },
  },
}
</script>

<style scoped lang="scss"></style>
