const apiHeaders = (context) => ({
  "Content-Type": "application/json",
  authorization: "Bearer " + context.$store.state.token,
  Accept: "application/json",
})

export default (context) => ({
  httpGetCategories() {
    return context.$http.get(
      `${context.$store.state.api_base_url_v3}/business/categories`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetCities() {
    return context.$http.get(
      `${context.$store.state.api_base_url}/information/cities`,
      {
        headers: apiHeaders(context),
      }
    )
  },
  httpGetPersonalInfo(axios) {
    return axios.get(`${context.state.api_base_url}/personal/info`, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${context.state.token}`,
        Accept: "application/json",
      },
    })
  },
})
