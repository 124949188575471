export default function sortFinancialListOnTop(list) {
  const financialServicesArray = []
  const restOfServices = []
  for (const [key, value] of list) {
    if (value.slice(0, 10) === "خدمات مالی") {
      financialServicesArray.push({ key, value })
    } else {
      restOfServices.push({ key, value })
    }
  }
  return [...financialServicesArray, ...restOfServices]
}
