<template>
  <!-- skeleton  for second modal -->
  <v-card v-if="secoundModal && !item" class="multi-box">
    <v-toolbar flat height="68" class="fixed_toolbar">
      <v-flex class="label mr-5">
        <span class="default_gray ma-1" />
      </v-flex>
    </v-toolbar>
    <div class="boxes">
      <div class="bus skeletonShadowLoading" />
    </div>
    <div class="boxes">
      <div class="bus bus-2 skeletonShadowLoading" />
    </div>
    <div class="boxes">
      <div class="bus bus-3 skeletonShadowLoading" />
    </div>
  </v-card>
  <!-- after item is loaded -->
  <v-card v-else class="multi-box">
    <toolbar
      :is-refunded="transactionInfo.item.refund_detail_ids.length !== 0"
      :status-channel="transactionInfo.statusChannel"
      :should-remove-the-cancel-button="
        !transactionInfo.time_prediction.is_cancelable
      "
      @closeClick="closeSecondModal"
      @toolbarBtnClick="toolbarBtnClickHanddler"
    />

    <v-layout column class="ppt-24 show-container">
      <!-- boxes -->
      <ShowModalBoxes :transaction-info="transactionInfo" />

      <!-- details shaparak settlement -->
      <ShaparakSettlementDetails
        v-if="isShaparakSettlement"
        :shaparak-settlement-details="
          transactionInfo?.shaparakSettlementDetails
        "
        @showDetails="showShaparakSettlementDetails($event)"
      />

      <!-- messages -->
      <TransactionsMessageBox
        v-if="transactionInfo.statusChannel"
        :transaction-info="transactionInfo"
        @revisedClick="showRevisedTransactionId"
        @moreDetailsClick="showMoreDetail($event)"
      />
      <!-- details -->
      <AggregateTransactionLimitationInfo
        v-if="$store.state.report.aggregateTransactionData.is_aggregate"
      />
      <MoreDetailsBox
        :transaction-info="transactionInfo"
        @onRevisedClick="showRevisedTransactionId"
        @openFormDetail="$emit('openFormDetail', $event)"
      />
    </v-layout>

    <cancelSettlement
      :status="deleteSettlement"
      :trans-id="item.id"
      @close="closeModal()"
    />

    <!--    not enough wallet-->
    <!--    <general-dialog-->
    <!--      :active="notEnoughWallet"-->
    <!--      logo="Failed.svg"-->
    <!--      content-class="confirmationMessageDialog"-->
    <!--      btn2-text="متوجه شدم"-->
    <!--      btn2-class="btn_small_normal"-->
    <!--      @btn2Clicked="closeModal()"-->
    <!--    >-->
    <!--      <template #extra>-->
    <!--        <div class="font_20 mt-5 mb-2">-->
    <!--          موجودی شما کافی نیست-->
    <!--        </div>-->
    <!--        <div-->
    <!--          class="font_4"-->
    <!--          style="text-align: center"-->
    <!--        >-->
    <!--          وجه بازگشتی از موجودی کیف پول شما برداشت می‌شود. لطفا زمانی که موجودی-->
    <!--          شما به {{ (item.amount - item.wage) | currency }}-->
    <!--          {{ currencyName }} رسید، دوباره اقدام کنید-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </general-dialog>-->

    <!--    limited refund-->
    <general-dialog
      :active="limitedRefund"
      logo="Failed.svg"
      content-class="confirmationMessageDialog"
      btn2-text="متوجه شدم"
      btn2-class="btn_small_normal"
      @btn2Clicked="closeModal()"
    >
      <template #extra>
        <div class="font_20 mt-5 mb-2">مبلغ تراکنش بیشتر از حد مجاز است</div>
        <div class="font_4" style="text-align: center">
          سقف بازگشت وجه ۵۰٫۰۰۰٫۰۰۰ تومان است
        </div>
      </template>
    </general-dialog>
  </v-card>
</template>

<script>
import { channels } from "@/mixins/channels.js"
import TransactionsMessageBox from "@/components/report/elements/show/messageBox/transactionMessageBox.vue"
import MoreDetailsBox from "@/components/report/elements/show/details/moreDetailsBox.vue"
import toolbar from "@/components/report/elements/show/toolbar/toolbar.vue"
import ShowModalBoxes from "@/components/report/elements/show/cards/showModalBoxes.vue"

import cancelSettlement from "./cancelSettlement.vue"
import generalDialog from "@/components/modals/generalDialog"
import ShaparakSettlementDetails from "@/components/report/elements/show/messageBox/shaparakSettlementDetails.vue"
import servicesRepository from "@/services/servicesRepository"
import AggregateTransactionLimitationInfo from "@/components/report/elements/show/details/AggregateTransactionLimitationInfo"

export default {
  name: "Show",
  components: {
    ShaparakSettlementDetails,
    cancelSettlement,
    generalDialog,
    MoreDetailsBox,
    toolbar,
    ShowModalBoxes,
    TransactionsMessageBox,
    AggregateTransactionLimitationInfo,
  },
  mixins: [channels],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    secoundModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteSettlement: false,
      tooltip_show: false,
      receipt_url_tooltip_show: false,
      tracking_code_tooltip_show: false,
      // notEnoughWallet: false,
      limitedRefund: false,
      resualtArray: [],
      formBoxArray: [],
      transactionsArray: [],
      channelName: "",
      withdrawDetail: null,
      pbvDetail: null,
      transactionInfo: {
        id: this.item?.id || 0,
        statusChannel: null,
        settlementOrTransaction: null,
        isTransactionRefund: false,
        isFinallySettlement: false,
        hasTimePrediction: false,
        actor: null,
        isActor: false,
        timing: null,
        withdrawDetail: false,
        creditDetail: null,
        pbvDetail: false,
        time_prediction: {
          settlement_done_time_prediction: null,
          is_admin_check_status: false,
          has_change_done_time_prediction: false,
          is_cancelable: false,
          has_change_port_to_paya: false,
          is_after_cancelable: false,
        },
        p2p: {
          secondAmount: null,
          thirdAmount: null,
          instantAmount: null,
          thirdDate: null,
          secondDate: null,
          instantDate: null,
        },
        refund: {
          source_transaction: null,
          data: null,
        },
        receiver: {
          name: null,
          business_owner: null,
          iban: null,
          slug: null,
          legal_name: null,
        },
        settlement_source: {
          source: null,
          account: null,
        },
        payer: {
          ip: null,
          description: null,
          iban: null,
          slug: null,
          legal_name: null,
          name: null,
          business_owner: null,
          mobile: null,
          email: null,
          phone: null,
          address: null,
          additional_fields: [],
        },
        item: {
          id: 0,
          amount: 0,
          wage: 0,
          shaparakWage: 0,
          revised_transaction_id: null,
          ref_id: null,
          receipt_url: null,
          status: null,
          channel: null,
          wallet: null,
          card_number: null,
          payment_date: null,
          is_shaparak_port: null,
          created_at: null,
          payment_number: null,
          track_id: null,
          description: null,
          result: null,
          form_id: null,
          form_title: null,
          logs: [],
          comments: [],
          refund_detail_ids: [],
          api_token: null,
          factorNumber: false,
          settlement_port: null,
        },
        shaparakSettlementDetails: null,
        is_auto_cash_in: null,
      },
    }
  },
  computed: {
    cancelSettlementCondition() {
      const condition = [
        "settlement_tashim_unsuccessful",
        "settlement_tashim_pending",
      ].includes(this.transactionInfo.statusChannel)

      return condition
    },
    refundCondition() {
      const condition =
        this.$store.state.business.hasOwnProperty("tools") &&
        this.$store.state.business.tools.refund.status == "ACTIVE" &&
        [
          "transaction_requestMoney_successful",
          "transaction_ipg_successful",
        ].includes(this.transactionInfo.statusChannel)

      return condition
    },
    settlementOrTransaction() {
      if ([2, -2, -3, -4, -5, -6, "FAILED", -8, 9].includes(this.item.status))
        return "settlement"
      // واریز ها
      else if ([-1, 1, 3, 4, 5, 6, 7, 10].includes(this.item.status))
        return "transaction"
      return null
    },
    hasTimePrediction() {
      return (
        this.item.time_prediction.p2p_time_prediction &&
        this.item.time_prediction.p2p_time_prediction !== null
      )
    },
    calcTiming() {
      let obj = {
        status: this.item.status,
        channel: this.item.channel,
        isRefund: this.item.refund,
        isFinallySettlement: this.isTransactionRefund,
        settlementPort: this.item?.settlement_port,
        shaparakSuccess: this.item?.tracking_code !== null,
        isAfterTimePrediction:
          this.item?.time_prediction?.is_after_time_prediction,
      }

      if (
        ["settlement_tashim_pending", "settlement_refund_pending"].includes(
          this.statusChannels(obj)
        )
      ) {
        return this.item.time_prediction.settlement_cancelable_time ?? null
      } else if (
        ["settlement_tashim_returned"].includes(this.statusChannels(obj))
      ) {
        return this.item.updated_at ?? null
      } else if (
        [
          "settlement_tashim_successful_first",
          "settlement_tashim_successful_port1",
        ].includes(this.statusChannels(obj))
      ) {
        return (
          this.item.time_prediction.settlement_paya_report_finally_time ?? null
        )
      }

      return null
    },
    isActor() {
      if (
        this.$store.state.isUser ||
        this.settlementOrTransaction == "transaction" ||
        [
          "settlement_p2p_successful",
          "settlement_subscriptionReturned_successful",
        ].includes(this.transactionInfo.statusChannel)
      )
        return false
      return true
    },
    isTransactionRefund() {
      return this.item.refund && this.item.refund != null
    },
    isFinallySettlement() {
      if (this.item.time_prediction.is_settlement_paya_report_finally) {
        return true
      }
      return false
    },
    isShaparakSettlement() {
      return [
        "settlement_shaparak_pending",
        "settlement_shaparak_successful",
        "settlement_shaparak_approved",
        "settlement_shaparak_failed",
      ].includes(this.transactionInfo.statusChannel)
    },
  },
  watch: {
    item: function (newValue) {
      if (newValue) {
        if (this.doseChannelMatch("direct_debit", this.item.channel))
          this.getWithdrawalDetail()
        else if (this.doseChannelMatch("pbv", this.item.channel))
          this.getPBVDetail()
        this.parseData()
        if (this.transactionInfo.isActor) this.getActor()
      }
    },
  },
  mounted() {
    this.transactionInfo.settlement_source = { ...this.item.settlement_source }
    if (!this.secoundModal) {
      this.parseData()
      if (this.doseChannelMatch("direct_debit", this.item.channel))
        this.getWithdrawalDetail()
      else if (this.doseChannelMatch("pbv", this.item.channel))
        this.getPBVDetail()

      if (this.transactionInfo.isActor) this.getActor()

      //get refund details
      if (this.item.refund_detail_ids?.length) {
        this.getRefundDetailsIfUserDoRefund()
      }
    }
    if (this.isShaparakSettlement) {
      this.getShaparakSettlementDetails()
    }

    if (
      this.transactionInfo.statusChannel === "transaction_cashIn_successful"
    ) {
      this.getTransactionRtgsDetail()
    }
  },
  methods: {
    getActor() {
      this.$http
        .get(
          this.$store.getters.apiUrlParam +
            "/transaction/" +
            this.item.id +
            "/actor-detail",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.transactionInfo.actor = response.data.data
          } else {
            this.transactionInfo.actor = null
          }
        })
        .catch((error) => {
          //callsnackbar
          this.transactionInfo.actor = null
        })
    },
    getTransactionRtgsDetail() {
      servicesRepository(this)
        .transaction.httpTransactionRtgsDetail(this.item.id)
        .then((response) => {
          this.transactionInfo.is_auto_cash_in =
            response.data.data.is_auto_cash_in
        })
        .catch((error) => {
          this.transactionInfo.is_auto_cash_in = null
        })
    },
    getPBVDetail() {
      this.$http
        .get(
          this.$store.state.api_base_url_withoutV +
            "/pbv/v1/user/checkouts/" +
            this.item.api_token,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.transactionInfo.pbvDetail = response.data.data
          if (this.transactionInfo.pbvDetail.bank_code == "102")
            this.transactionInfo.pbvDetail.bank_code = "062"
          this.$getMyBanksProperty.forEach((item, index) => {
            if (
              item.code.indexOf(this.transactionInfo.pbvDetail.bank_code) > -1
            ) {
              this.transactionInfo.pbvDetail.bank_name = item.persianName
            }
          })
        })
        .catch((error) => {})
    },
    getWithdrawalDetail() {
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") +
            "/subscription/withdrawal/show-with-gateway-transaction/" +
            this.item.id,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.transactionInfo.withdrawDetail = response.data.result.withdrawal
          if (this.transactionInfo.withdrawDetail.bank_code === "102")
            this.transactionInfo.withdrawDetail.bank_code = "062"
          this.$getMyBanksProperty.forEach((item, index) => {
            if (
              item.code.indexOf(this.transactionInfo.withdrawDetail.bank_code) >
              -1
            ) {
              this.transactionInfo.withdrawDetail.bank_name = item.persianName
            }
          })
        })
        .catch((error) => {})
    },
    keysHelper(keyList, item, arrayKeyList = []) {
      let response = {}
      for (let el in keyList) {
        response[keyList[el]] = item[keyList[el]] ?? null
      }

      //array of array (like comments, logs, additional filed ...)
      for (let el in arrayKeyList) {
        response[arrayKeyList[el]] = item[arrayKeyList[el]] ?? []
      }

      return response
    },
    parseData() {
      if (!this.item) return

      //first thing to set is isFinallySettlement & isTransactionRefund
      this.transactionInfo.isFinallySettlement = this.isFinallySettlement
      this.transactionInfo.isTransactionRefund = this.isTransactionRefund

      let obj = {
        status: this.item.status,
        channel: this.item.channel,
        isRefund: this.item.refund,
        isFinallySettlement: this.isFinallySettlement,
        settlementPort: this.item?.settlement_port,
        shaparakSuccess: this.item?.tracking_code !== null,
        isAfterTimePrediction:
          this.item?.time_prediction?.is_after_time_prediction,
      }

      this.transactionInfo.statusChannel = this.statusChannels(obj) ?? null

      this.transactionInfo.isActor = this.isActor
      this.transactionInfo.settlementOrTransaction =
        this.settlementOrTransaction
      this.transactionInfo.hasTimePrediction = this.hasTimePrediction
      this.transactionInfo.timing = this.calcTiming
      this.transactionInfo.withdrawDetail = this.withdrawDetail
      this.transactionInfo.pbvDetail = this.pbvDetail

      this.transactionInfo.time_prediction.settlement_done_time_prediction =
        this.item.time_prediction
          ? this.item.time_prediction.settlement_done_time_prediction ?? null
          : null

      this.transactionInfo.time_prediction.is_admin_check_status =
        this.item.time_prediction.is_admin_check_status ?? false

      this.transactionInfo.time_prediction.has_change_done_time_prediction =
        this.item.time_prediction.has_change_done_time_prediction ?? false

      this.transactionInfo.time_prediction.is_cancelable =
        this.item.time_prediction.is_cancelable ?? false

      this.transactionInfo.time_prediction.is_after_cancelable =
        this.item.time_prediction.is_after_cancelable ?? false

      this.transactionInfo.time_prediction.has_change_port_to_paya =
        this.item.time_prediction.has_change_port_to_paya ?? false

      if (
        ["settlement_p2p_successful", "transaction_p2p_successful"].includes(
          this.transactionInfo.statusChannel
        ) &&
        this.hasTimePrediction &&
        this.item.time_prediction.p2p_time_prediction
      ) {
        this.p2pTimeHandler()
      }

      // refund info
      if (this.transactionInfo.isTransactionRefund) {
        this.transactionInfo.refund.source_transaction =
          this.item.refund.source_transaction ?? null
      }

      //receiver info
      if (this.item.receiver) {
        let receiverList = ["name", "slug", "iban", "legal_name"]
        this.transactionInfo.receiver = this.keysHelper(
          receiverList,
          this.item.receiver
        )
        this.transactionInfo.receiver.business_owner =
          this.item.receiver.name !== this.item.receiver.business_owner
            ? this.item.receiver.business_owner ?? null
            : null
      }

      // payer Info
      if (this.item.payer) {
        let payerList = [
          "description",
          "slug",
          "iban",
          "ip",
          "name",
          "mobile",
          "email",
          "phone",
          "address",
          "legal_name",
        ]
        this.transactionInfo.payer = this.keysHelper(
          payerList,
          this.item.payer,
          ["additional_fields"]
        )

        this.transactionInfo.payer.business_owner =
          this.item.payer.name !== this.item.payer.business_owner
            ? this.item.payer.business_owner ?? null
            : null
      }

      //items
      let itemList = [
        "api_token",
        "form_title",
        "form_id",
        "channel",
        "result",
        "description",
        "payment_number",
        "wallet",
        "id",
        "card_number",
        "tracking_code",
        "track_id",
        "ref_id",
        "status",
        "amount",
        "payment_date",
        "created_at",
        "effective_at_jalali",
        "wage",
        "shaparakWage",
        "revised_transaction_id",
        "receipt_url",
        "factorNumber",
        "is_shaparak_port",
        "settlement_port",
      ]
      // array of array - items
      this.transactionInfo.item = this.keysHelper(itemList, this.item, [
        "logs",
        "comments",
        "refund_detail_ids",
      ])
      this.transactionInfo.creditDetail = {
        id: { label: "شناسه واریز", order: 1 },
        customer: { label: "مشتری", order: 1 },
        port: this.translatePort(this.item?.port),
        channel: this.item?.channel,
        factorNumber: this?.item?.factorNumber,
        wage: this.item?.wage.toString(),
      }
    },
    translatePort(port) {
      if (port === "TARA") {
        return "  پرداخت اعتباری تارا"
      }
      return port
    },
    toolbarBtnClickHanddler() {
      if (this.cancelSettlementCondition) this.deleteSettlement = true
      else if (this.refundCondition) this.openRefundModal()
    },
    openRefundModal() {
      // if (
      //   this.transactionInfo.item.amount > this.$store.state.business.wallet
      // ) {
      //   this.notEnoughWallet = true
      // } else
      if (
        this.transactionInfo.item.amount - this.transactionInfo.item.wage >
        50000000
      ) {
        this.limitedRefund = true
      } else this.$emit("showAddRefund")
    },
    closeModal() {
      this.$emit("closeModal", { status: false })
      this.deleteSettlement = false
      // this.notEnoughWallet = false
      this.limitedRefund = false
    },
    closeSecondModal() {
      this.secoundModal
        ? this.$emit("closeSecoundModal")
        : this.$emit("closeModal", { status: false })
    },
    showRevisedTransactionId(transactionId) {
      if (this.secoundModal) {
        this.$emit("closeSecoundModal")
      } else {
        this.$emit("showRevisedTransaction", transactionId)
      }
    },
    p2pTimeHandler() {
      // set second part amount and time
      if (this.item.time_prediction.p2p_time_prediction["second_part"]) {
        this.transactionInfo.p2p.secondAmount =
          this.item.time_prediction.p2p_time_prediction["second_part"][
            "amount"
          ] ?? null

        this.transactionInfo.p2p.secondDate = this.item.time_prediction
          .p2p_time_prediction["second_part"]["date"]
          ? this.item.time_prediction.p2p_time_prediction["second_part"][
              "date"
            ].split(" - ")[1]
          : null
      }

      // set third part amount and time
      if (this.item.time_prediction.p2p_time_prediction["third_part"]) {
        this.transactionInfo.p2p.thirdAmount =
          this.item.time_prediction.p2p_time_prediction["third_part"][
            "amount"
          ] ?? null

        this.transactionInfo.p2p.thirdDate = this.item.time_prediction
          .p2p_time_prediction["third_part"]["date"]
          ? this.item.time_prediction.p2p_time_prediction["third_part"][
              "date"
            ].split(" - ")[1]
          : null
      }

      // set instant  amount and time
      if (this.item.time_prediction.p2p_time_prediction["instant"]) {
        this.transactionInfo.p2p.instantAmount =
          this.item.time_prediction.p2p_time_prediction["instant"]["amount"] ??
          null

        this.transactionInfo.p2p.instantDate = this.item.time_prediction
          .p2p_time_prediction["instant"]["date"]
          ? this.item.time_prediction.p2p_time_prediction["instant"][
              "date"
            ].split(" - ")[1]
          : null
      }
    },
    getRefundDetailsIfUserDoRefund() {
      this.$http
        .get(
          this.$store.getters.apiWithVersionParam("3") +
            `/transaction/${this.item.id}/refunds`
        )
        .then((resp) => {
          this.transactionInfo.refund.data = resp.data.data
        })
    },
    showMoreDetail() {
      this.$emit("moreDetailsClick")
    },
    getShaparakSettlementDetails() {
      this.$http
        .get(
          this.$store.getters.apiUrlParamV3 +
            `/transaction/${this.transactionInfo?.id}/detail`
        )
        .then((response) => {
          this.transactionInfo.shaparakSettlementDetails = response.data?.data
        })
    },
    showShaparakSettlementDetails(obj) {
      this.$emit("closeModal", { searchParams: obj })
    },
  },
}
</script>

<style lang="scss" scoped>
.show-container {
  overflow: auto;
  height: 100vh;
}
.label {
  max-width: 200px;
}

.boxes {
  display: flex;
  margin: 0 30px;
  padding-top: 20px;

  &:first-of-type {
    padding-top: 100px;
  }

  .bus {
    background-color: $van_color11;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 158px;
    width: 100%;

    &.bus-2 {
      height: 80px;
    }

    &.bus-3 {
      height: 250px;
    }
  }
}
</style>
