<template>
  <v-layout v-if="currentComponent" :column="$vuetify.breakpoint.xsOnly">
    <v-flex v-if="item.label" class="label d-flex align-center">
      {{ item.label }}
      <v-tooltip v-if="item.label === 'شناسه تسویه'" top>
        <template #activator="{ on }">
          <v-icon
            size="20"
            style="width: 20px; margin-right: 4px; align-self: center"
            class="van_color03--text"
            v-on="on"
          >
            mdi_info_outline
          </v-icon>
        </template>
        <span class="font_33">
          با شناسه تسویه (Track id) امکان پیگیری
          <br />
          تراکنش را تا پیش از ارسال به بانک دارید.
        </span>
      </v-tooltip>
    </v-flex>
    <component
      :is="currentComponent.name"
      :item="specifyTheItem"
      v-on="{ ...currentComponent.events }"
    />
  </v-layout>
</template>

<script>
import revisedDetail from "@/components/report/elements/show/details/revisedDetail.vue"
import subscriptionDetails from "@/components/report/elements/show/details/subscriptionDetails.vue"
import pbvDetail from "@/components/report/elements/show/details/pbvDetail.vue"
import withdrawShimmer from "@/components/report/elements/show/details/withdrawShimmer.vue"
import pbvShimmer from "@/components/report/elements/show/details/pbvShimmer"
import wageDetail from "@/components/report/elements/show/details/wageDetail.vue"
import actorDetail from "@/components/report/elements/show/details/actorDetail.vue"
import generalDetail from "@/components/report/elements/show/details/generalDetail.vue"
import comments from "@/components/report/elements/show/details/comments.vue"

export default {
  components: {
    revisedDetail,
    withdrawShimmer,
    wageDetail,
    actorDetail,
    comments,
    subscriptionDetails,
    pbvShimmer,
    generalDetail,
    pbvDetail,
  },
  props: ["item", "withdrawDetail", "pbvDetail"],
  data() {
    return {
      manipulatedItem: {},
    }
  },
  computed: {
    currentComponent() {
      let comp = {}
      // revised
      if (this.item.showRevisedTransaction) {
        comp = {
          name: "revisedDetail",
          events: {
            revisedClick: this.revised_click,
          },
        }
      }
      //withdrawShimmer
      else if (!this.withdrawDetail && this.item.shimmerWidth) {
        comp = {
          name: "withdrawShimmer",
        }
      }
      //pbvShimmer
      else if (!this.pbvDetail && this.item.shimmerWidth) {
        comp = {
          name: "pbvShimmer",
        }
      }
      //wageDetail
      else if (this.item.wage) {
        comp = {
          name: "wageDetail",
        }
      }
      //actorDetail
      else if (this.item.actor) {
        comp = {
          name: "actorDetail",
        }
      }
      //comments
      else if (this.item.comments) {
        comp = {
          name: "comments",
        }
      }
      //subscriptionDetails
      else if (this.item.subscription) {
        comp = {
          name: "subscriptionDetails",
        }
      }
      //pbvDetails
      else if (this.item.pbv) {
        comp = {
          name: "pbvDetail",
        }
      }

      // generalDetail
      else {
        comp = {
          name: "generalDetail",
          events: {
            openFormDetail: this.open_form_detail,
          },
        }
      }
      return comp
    },
    specifyTheItem() {
      return this.item.label === "توضیحات" ? this.manipulatedItem : this.item
    },
  },
  mounted() {
    this.preventMetaTagInDescription()
  },
  methods: {
    revised_click(id) {
      if (id) {
        this.$emit("revisedClick", id)
      }
    },
    open_form_detail(id) {
      this.$emit("openFormDetail", id)
    },
    preventMetaTagInDescription() {
      this.manipulatedItem = { ...this.item }
      const isMetaTag = function (htmlString) {
        const metaTagRegex = /<meta\s+[^>]*?/i
        return metaTagRegex.test(htmlString)
      }

      if (this.manipulatedItem.label === "توضیحات") {
        if (isMetaTag(this.manipulatedItem.value)) {
          this.manipulatedItem.value = ""
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  min-width: 110px;
}
.linkBorder {
  display: flex;
  max-width: fit-content;
}
</style>
