<template>
  <LeftModal
    toolbar-name="شارژ کیف‌پول"
    class="cash-in-modal"
    @closeModal="$emit('closeModal')"
  >
    <div
      v-if="$store.state.business.status === 0"
      class="pending-business-notification font_2"
    >
      {{ lang.report.pendingBusinessNotification }}
    </div>
    <div v-if="showAddCashIn && tab === 0" slot="toolbarItems">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-img
            width="24"
            aspect-ratio="1"
            v-bind="attrs"
            :src="require(`@/assets/img/More.svg`)"
            v-on="on"
          />
        </template>
        <v-list min-width="234">
          <v-list-item v-for="item in menuItemsShowsItem" :key="item.value">
            <v-list-item-title
              class="font_2"
              style="cursor: pointer"
              @click="clickOnListContent(item)"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <template
      v-if="
        (showAddCashIn || manualCashInActive) &&
        $store.state.business.status === 1
      "
    >
      <v-layout column style="border-top: 1px solid #f5f5f5">
        <v-tabs v-model="tab" class="pr-8 van_color13">
          <v-tab class="tab-title px-1 font-size-12"> واریز بانکی</v-tab>
          <v-tab class="tab-title px-1 font-size-12"> شناسه واریز</v-tab>
        </v-tabs>
      </v-layout>
      <v-layout column justify-space-between class="pa-8">
        <v-flex class="mb-2">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <template v-if="showAddCashIn">
                <ManualCashInCard
                  :is-primary="isThereAutoCashInIban"
                  @send-out-receipt="close_modal"
                />
                <AutoCashInCard
                  v-if="!isThereAutoCashInIban"
                  @setup-auto-cash-in="showAutoCashIn"
                />
                <AutoCashInIbanInfo
                  v-else
                  :auto-cash-in-iban="autoCashInIban[0]"
                />
              </template>
              <template v-else>
                <general-card>
                  <CashInActivation
                    @openActiveTools="openActiveCashIn"
                    @showIntroduce="showDirectIntroduce"
                  />
                </general-card>
              </template>
            </v-tab-item>
            <v-tab-item>
              <general-card>
                <manual-cash-in
                  @showManualCashInIntroduce="showManualCashInIntroduce"
                />
              </general-card>
              <NewCashInIban />
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </template>
    <template v-else>
      <div class="pa-8">
        <general-card>
          <CashInActivation
            @openActiveTools="openActiveCashIn"
            @showIntroduce="showDirectIntroduce"
          />
        </general-card>
        <general-card>
          <manual-cash-in
            @showManualCashInIntroduce="showManualCashInIntroduce"
          />
        </general-card>
      </div>
    </template>

    <!--    dialogs     -->

    <!-- introduce modal -->
    <v-dialog
      v-if="introduce"
      v-model="introduce"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      content-class="vanmodal"
      no-click-animation
      transition="slide-x-transition"
    >
      <introduce
        v-if="directCashInIntroduce"
        :tool-status="cashInStatus"
        whichtool="cash-in"
        @activeTool="openActiveCashIn"
        @closeModal="close_modal()"
        @suspendTool="showCashInPreInActive"
      />
      <introduce
        v-if="manualCashInIntroduce"
        :tool-status="''"
        whichtool="cash-in-manual"
        @activeTool="openActiveCashIn"
        @closeModal="close_modal()"
        @suspendTool="showCashInPreInActive"
      />
    </v-dialog>
    <!-- cash in dialog activation -->
    <GeneralDialog
      :active="cashInActivate"
      :btn1-loading="cashInActivateLoading"
      :width="580"
      btn1-text="شروع کنید"
      btn2-class="btn_small_normal"
      btn2-text="انصراف"
      content-class="confirmationMessageDialog"
      description="با استفاده از این قابلیت، کیف‌پول وندارتان را شارژ کنید."
      style="height: 100%"
      title="مراحل اتصال حساب بانکی به وندار"
      @btn1Clicked="activeCashIn()"
      @btn2Clicked="close_modal()"
    >
      <template #extra>
        <v-layout
          align-center
          class="font_2 mt-6 pa-3 van_color11"
          style="border-radius: 4px; width: 100%"
        >
          <v-flex class="pl-4" style="max-width: fit-content">
            <img
              :src="require('@/assets/img/newIcon/internetBank.svg')"
              class="d-block"
            />
          </v-flex>
          <v-flex>
            <div class="font_28">اینترنت بانک</div>
            <div class="font_31 mt-1">
              از طریق برنامه کیلید یا اینترنت بانک، وارد حساب کاربری بانک آینده
              خود شوید.
            </div>
          </v-flex>
        </v-layout>
        <v-layout
          align-center
          class="font_2 mt-2 pa-3 van_color11"
          style="border-radius: 4px; width: 100%"
        >
          <v-flex class="pl-4" style="max-width: fit-content">
            <img
              :src="require('@/assets/img/newIcon/key.svg')"
              class="d-block"
            />
          </v-flex>
          <v-flex>
            <div class="font_28">دسترسی به حساب‌ها</div>
            <div
              v-if="$store.state.business.business_type === 'personal'"
              class="font_31 mt-1"
            >
              دسترسی شماره حساب بانک آینده مالک کسب‌و‌کار را به وندار دهید
            </div>
            <div v-else class="font_31 mt-1">
              دسترسی شماره حساب بانک آینده کسب‌و‌کار حقوقی خود را به وندار دهید
            </div>
          </v-flex>
        </v-layout>
      </template>
    </GeneralDialog>

    <!-- cash in dialog preInactive -->
    <GeneralDialog
      :active="cashInPreInActive"
      :width="580"
      btn2-text="منصرف شدم"
      content-class="confirmationMessageDialog"
      custom-style="height:400px"
      logo="newIcon/error_close.svg"
      style="height: 100%"
      title="اتصال واریز بانکی را لغو می‌کنید؟"
      @btn2Clicked="cashInPreInActive = false"
    >
      <template #extra>
        <v-layout
          align-center
          class="font_2 mt-4 pa-3 van_color11"
          style="border-radius: 4px; width: 100%"
        >
          <v-flex>
            <div class="font_28">لغو اتصال واریز بانکی</div>
            <div class="font_31 mt-1">
              اعتبار قرارداد واریز بانکی پس از غیرفعال‌سازی ادامه دارد
            </div>
          </v-flex>
          <v-flex class="align-self-center" style="max-width: 84px">
            <v-btn
              class="btn btn_small_normal error ma-auto"
              dark
              height="36px"
              style="width: 84px !important; background: #d44d42 !important"
              text
              @click="showCashInInactiveModal()"
            >
              <span>لغو اتصال</span>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout
          align-center
          class="font_2 mt-2 pa-3 van_color11"
          style="border-radius: 4px; width: 100%"
        >
          <v-flex>
            <div class="font_28">فسخ قرارداد</div>
            <div class="font_31 mt-1">
              قرارداد به طور کامل فسخ و اتصال دوباره، نیازمند ارسال قرارداد جدید
              است
            </div>
          </v-flex>
          <v-flex class="align-self-center" style="max-width: 84px">
            <v-btn
              class="btn btn_small_normal ma-auto"
              dark
              height="36px"
              outlined
              style="width: 84px !important"
              text
              @click="showCashInTerminateModal"
            >
              <span>فسخ قرارداد</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </template>
    </GeneralDialog>

    <!-- cash in inactive modal -->
    <GeneralDialog
      :active="cashInInActive"
      :bt1-loading="revokeLoading"
      btn1-class="btn_small_normal error"
      btn1-text="لغو اتصال"
      btn2-class="btn_small_normal"
      btn2-text="منصرف شدم"
      content-class="confirmationMessageDialog"
      description="این ابزار از کار می افتد ولی اطلاعات آن حذف نمی شود. اگر قرارداد شما مهلت داشته باشد در فعال‌سازی بعدی از آن استفاده می شود."
      logo="newIcon/error_close.svg"
      title="اتصال واریز بانکی را لغو می‌کنید؟"
      @btn1Clicked="revokeCashIn()"
      @btn2Clicked="showCashInPreInActive()"
    />

    <!-- cash in terminate modal -->
    <GeneralDialog
      :active="cashInTerminate"
      :btn1-loading="terminateLoading"
      btn1-class="btn_small_normal error"
      btn1-text="ثبت درخواست"
      btn2-class="btn_small_normal"
      btn2-text="منصرف شدم"
      content-class="confirmationMessageDialog"
      description="این ابزار از کار افتاده و درخواست شما توسط پشتیبان وندار بررسی می‌شود. یادتان باشد که برای فعال‌سازی دوباره، باید همه مراحل از ابتدا انجام شود"
      logo="newIcon/error_close.svg"
      title="قرارداد واریز بانکی فسخ شود؟"
      @btn1Clicked="terminateCashIn()"
      @btn2Clicked="showCashInPreInActive()"
    />

    <v-dialog
      v-if="autoCashInModal"
      v-model="autoCashInModal"
      content-class="vanmodal"
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      no-click-animation
    >
      <AutoCashInModal v-if="autoCashInModal" @closeModal="close_modal" />
    </v-dialog>

    <GeneralDialog
      :active="isShowDeActiveCashInModal"
      content-class="confirmationMessageDialog"
      logo="newIcon/error_close.svg"
      style="height: 100%"
      title="واریز بانکی خودکار حذف شود؟"
      btn1-text="بله، اعمال شود"
      :btn1-loading="deActiveButtonLoading"
      btn1-class="btn_small_normal error"
      btn2-text="انصراف"
      btn2-class="btn_small_normal"
      @btn1Clicked="doDeActiveAutoCashIn"
      @btn2Clicked="isShowDeActiveCashInModal = false"
    >
      <template #extra>
        <span
          class="font_2 pa-5"
          style="background: #faedec; text-align: center; border-radius: 4px"
        >
          با حذف این تنظیمات، شارژ کیف‌پول به صورت خودکار انجام نخواهد شد.
        </span>
        <section v-if="autoCashInIban" class="iban-card">
          <div class="iban-card__row">
            <span class="font-11-no-666-17">صاحب حساب مبدا</span>
            <span class="font_2">
              {{ autoCashInIban[0]?.account_owner[0].firstName }}
              {{ autoCashInIban[0]?.account_owner[0].lastName }}
            </span>
          </div>
          <div class="iban-card__row">
            <span class="font-11-no-666-17">شماره شبا</span>
            <span class="font_2">
              <span>
                {{ autoCashInIban[0]?.IBAN }}
              </span>
            </span>
          </div>
          <div class="iban-card__row">
            <span class="font-11-no-666-17">مبلغ</span>
            <span class="font_2">{{ autoCashInIban[0]?.price_type }}</span>
          </div>
        </section>
      </template>
    </GeneralDialog>
  </LeftModal>
</template>

<script>
import CashInActivation from "@/components/report/modals/addCashIn/elements/cashInActivation"
import ManualCashIn from "@/components/report/modals/addCashIn/elements/manualCashIn"
import GeneralDialog from "@/components/modals/generalDialog"
import Introduce from "@/components/tools/modals/introduce"
import NewCashInIban from "./elements/NewCashInIban"
import LeftModal from "../../../modals/leftModal"
import GeneralCard from "../../../elements/generalCard"
import AutoCashInModal from "@/components/report/modals/addCashIn/autoCashInModal.vue"
import ManualCashInCard from "@/components/report/modals/addCashIn/elements/ManualCashInCard"
import AutoCashInCard from "@/components/report/modals/addCashIn/elements/AutoCashInCard"
import AutoCashInIbanInfo from "@/components/report/modals/addCashIn/elements/AutoCashInIbanInfo"
import servicesRepository from "@/services/servicesRepository"
import lang from "@/lang/languageResource.json"

export default {
  name: "CashInModal",
  components: {
    AutoCashInModal,
    GeneralCard,
    LeftModal,
    NewCashInIban,
    Introduce,
    GeneralDialog,
    ManualCashIn,
    CashInActivation,
    ManualCashInCard,
    AutoCashInCard,
    AutoCashInIbanInfo,
  },
  props: {
    isAutoCashInModalActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: 0,
      cashInActivate: false,
      cashInPreInActive: false,
      cashInInActive: false,
      cashInTerminate: false,
      cashInActivateLoading: false,
      revokeLoading: false,
      terminateLoading: false,
      disable: false,
      introduce: false,
      directCashInIntroduce: false,
      manualCashInIntroduce: false,

      //auto cash-in
      autoCashInModal: false,
      autoCashInIban: null,
      menuItems: [
        { title: "لغو اتصال واریز بانکی", value: "revokeCashIn", isShow: true },
        {
          title: "حذف واریز بانکی خودکار",
          value: "deActiveAutoCashIn",
          isShow: false,
        },
        {
          title: "اتصال واریز بانکی",
          value: "activeCashIn",
          isShow: true,
        },
      ],
      isShowDeActiveCashInModal: false,
      deActiveButtonLoading: false,
      lang,
    }
  },
  computed: {
    showAddCashIn() {
      return (
        !this.$store.state.isUser &&
        (this.$store.state.business.role === "owner" ||
          this.$store.state.business.role === "admin" ||
          this.$store.state.business.role === "accountant") &&
        this.$store.state.business.hasOwnProperty("tools") &&
        this.$store.state.business.tools["cash-in"].status === "ACTIVE"
      )
    },
    manualCashInActive() {
      return this.$store.state.business?.cash_in_code
    },
    cashInStatus() {
      if (!this.$store.state.business.hasOwnProperty("tools")) return "INACTIVE"

      return this.$store.state[this.$store.getters.userOrBuisenss].tools[
        "cash-in"
      ].status
    },
    isThereAutoCashInIban() {
      return this.autoCashInIban && this.autoCashInIban.length !== 0
    },
    menuItemsShowsItem() {
      return this.menuItems.filter((item) => item.isShow === true)
    },
  },
  watch: {
    introduce: function (newValue) {
      if (!newValue) {
        this.close_modal()
        this.$store.commit("changeShowSecondModal", false)
      }
    },
    autoCashInModal(newValue) {
      if (!newValue) {
        this.$store.commit("changeShowSecondModal", false)
        this.$emit("close-auto-cash-in-modal")
      }
    },
    autoCashInIban(newValue) {
      if (!newValue || newValue.length === 0) {
        this.deleteRemovingAutoCashInItemOnMenu()
      } else {
        this.addRemovingAutoCashInItemOnMenu()
      }
    },
    isAutoCashInModalActive(val) {
      if (val) {
        this.showAutoCashIn()
      }
    },
  },
  mounted() {
    if (this.showAddCashIn) {
      this.autoCashInIndex()
    }
  },
  methods: {
    showDirectIntroduce() {
      this.directCashInIntroduce = true
      this.introduce = true
      this.$store.commit("changeShowSecondModal", true)
    },
    showManualCashInIntroduce() {
      this.manualCashInIntroduce = true
      this.introduce = true
      this.$store.commit("changeShowSecondModal", true)
    },
    openActiveCashIn() {
      this.cashInActivate = true
    },
    showCashInPreInActive() {
      this.cashInInActive = false
      this.cashInTerminate = false
      this.cashInPreInActive = true
    },
    showCashInInactiveModal() {
      this.cashInPreInActive = false
      this.cashInInActive = true
    },
    showCashInTerminateModal() {
      this.cashInPreInActive = false
      this.cashInTerminate = true
    },
    activeCashIn() {
      this.cashInActivateLoading = true
      servicesRepository(this)
        .cashIn.httpCashInEnable()
        .then((response) => {
          window.location = response.data.redirect_url
        })
        .finally(() => {
          this.cashInActivateLoading = false
        })
    },
    revokeCashIn() {
      this.revokeLoading = true
      servicesRepository(this)
        .cashIn.httpRevokeCashIn()
        .then((response) => {
          this.$store.commit(
            "callSuccessSnackbar",
            "ابزار واریز بانکی غیرفعال شد"
          )
          this.resetTools()
          this.revokeLoading = false
          this.cashInInActive = false
          this.close_modal()
        })
        .catch(() => {
          this.revokeLoading = false
          this.cashInInActive = false
        })
    },
    terminateCashIn() {
      this.terminateLoading = true
      servicesRepository(this)
        .cashIn.httpTerminateCashIn()
        .then((response) => {
          this.$store.commit(
            "callSuccessSnackbar",
            "درخواست فسخ قرارداد ثبت شد"
          )
          this.terminateLoading = false
          this.cashInTerminate = false
          this.resetTools()
        })
        .catch(() => {
          this.terminateLoading = false
          this.cashInTerminate = false
        })
    },
    close_modal(obj) {
      this.cashInActivate = false
      this.cashInPreInActive = false
      this.cashInInActive = false
      this.cashInTerminate = false
      this.introduce = false
      this.directCashInIntroduce = false
      this.manualCashInIntroduce = false
      this.autoCashInModal = false
      if (obj && obj.status) {
        this.$emit("closeModal", {
          status: true,
          receiptUrl: obj.receiptUrl,
        })
      }
      if (obj?.activeAutoCashIn) {
        this.autoCashInIndex()
      }
    },
    resetTools() {
      this.$store.commit("eraseToolsObject")
      this.$store.dispatch(
        "retrieveBusinessObject",
        this.$store.state.business.business_name
      )
    },
    showAutoCashIn() {
      this.autoCashInModal = true
      this.$store.commit("changeShowSecondModal", true)
    },
    autoCashInIndex() {
      servicesRepository(this)
        .cashIn.httpGetAutoCashInInfo()
        .then((response) => {
          this.autoCashInIban = response.data.data
        })
    },
    addRemovingAutoCashInItemOnMenu() {
      this.menuItems[1].isShow = true
    },
    deleteRemovingAutoCashInItemOnMenu() {
      this.menuItems[1].isShow = false
    },
    clickOnListContent(item) {
      if (item.value === "deActiveAutoCashIn") {
        this.isShowDeActiveCashInModal = true
      } else if (item.value === "revokeCashIn") {
        this.showCashInPreInActive()
      } else if (item.value === "activeCashIn") {
        this.openActiveCashIn()
      }
    },
    doDeActiveAutoCashIn() {
      this.deActiveButtonLoading = true
      const queryParam = this.autoCashInIban?.[0]?.id
      servicesRepository(this)
        .cashIn.httpDeActiveAutoCashIn(queryParam)
        .then(() => {
          this.$store.commit(
            "callSuccessSnackbar",
            "واریز بانکی خودکار غیرفعال شد."
          )
          this.autoCashInIban = null
          this.autoCashInIndex()
          this.deActiveButtonLoading = false
          this.isShowDeActiveCashInModal = false
        })
        .catch(() => {
          this.deActiveButtonLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.iban-card {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: thin dashed #bbbbbb;
    padding: 4px 0;
  }

  &__row:last-child {
    border-bottom: none;
  }
}

.pending-business-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $van_color19;
  height: 48px;
}
</style>
