import { store } from "@/store"

const getCookie = function (cname) {
  const name = cname + "="
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

const eraseCookie = function (cname) {
  const baseDomain = "." + store.state.main_domain
  const dashDomain = `${store.state.dash_name}.${store.state.main_domain}`

  function eraseDocumentCookie(domain) {
    document.cookie = `${cname}=; domain=${domain}; max-age=0; path=/`
  }

  eraseDocumentCookie(baseDomain)
  eraseDocumentCookie(dashDomain)
}

const setCookie = function (obj) {
  const token = obj.token
  const dashDomain = `${store.state.dash_name}.${store.state.main_domain}`
  document.cookie = `${obj.name}=${token}; domain=${dashDomain}; max-age=${obj.expireTime}; path=/`
}

export { setCookie, getCookie, eraseCookie }
