/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import Vue from "vue"
import app from "./App.vue"
import router from "./router"
import { store } from "./store"
// import fonts from 'material-design-icons-iconfont'
import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import "./modules/digittoword/digittoword"
import bankList from "./plugins/bankList/index"
import "./filters/currency"
import vuetify from "./plugins/vuetify"
import "vuetify/dist/vuetify.min.css"
import axios from "./plugins/axios"
import "@/mixins/general.js"
import { eraseCookie, getCookie, setCookie } from "./utils/cookie"
import "./assets/style.scss"

Vue.use(bankList)

window.moment = require("moment")

if (store.state.is_sentry_active) {
  Sentry.init({
    dsn: "https://eb8ef587f3ae4abaa13af84c05383a04@sentry.vandar.ir/7",
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
    ],
    environment: process.env.VUE_APP_ENV,
    release: process.env.VUE_APP_SENTRY_RELEASE,
  })
  /*Sentry.configureScope(function(scope) {
     scope.setUser({"ip_address": });
   })*/
}

//GOOGLE ADS AND GOOGLE ADWORDS
window.dataLayer = window.dataLayer || []

function gtag() {
  dataLayer.push(arguments)
}
gtag("js", new Date())
gtag("config", "UA-132827439-1")
gtag("config", "AW-662065773")

//Event snippet for Enter page view Form Campaign conversion page
Vue.prototype.$gTagLoadForm = function () {
  //maybe add some defaults etc.
  gtag("event", "conversion", { send_to: "AW-662065773/wAnaCNC76skBEO2k2bsC" })
}

//Event snippet for Enter page view IPG Campaign conversion page
Vue.prototype.$gTagLoadIpg = function () {
  //maybe add some defaults etc.
  gtag("event", "conversion", { send_to: "AW-662065773/tJ3lCM_79MkBEO2k2bsC" })
}

//Event snippet for Click | Registered | (Form OR IPG) Campaign conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button.
function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url
    }
  }
  gtag("event", "conversion", {
    send_to: "AW-662065773/-fQ-COGi3MkBEO2k2bsC",
    value: 20.0,
    currency: "TRY",
    event_callback: callback,
  })
  gtag("event", "conversion", {
    send_to: "AW-662065773/Dr0aCLKF9ckBEO2k2bsC",
    value: 2.0,
    currency: "TRY",
    event_callback: callback,
  })
  return false
}
Vue.prototype.$clickRegister = function (url) {
  //maybe add some defaults etc.
  gtag_report_conversion(url)
}

Vue.config.productionTip = false

if (process.env.VUE_APP_ENV === "production") {
  Vue.config.devtools = false
} else if (process.env.VUE_APP_ENV === "development") {
  Vue.config.devtools = true
}

// Vue.use(fonts)

Vue.filter("timeFormat", function (value) {
  if (value) {
    return moment(String(value)).format("HH:mm")
  }
})

Vue.filter("convertPersianDigitToLatin", function (str) {
  if (!str) {
    return str
  }
  return str.replace(/[۰-۹]/g, (str) => "۰۱۲۳۴۵۶۷۸۹".indexOf(str))
})
Vue.filter("thousandSeprator", function (value) {
  if (value === null || !value) {
    return 0
  }
  return "" + value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
})

/**
 *
 * ROUTER FUNCTIONS
 */

const retreiveBusiness = async (to, next) => {
  //get Business
  await store
    .dispatch("retrieveBusinessObject", to.path.split("/")[2])
    .then((response) => {
      if (
        to.matched.some((record) => record.meta.requireRequestMoneyPermission)
      ) {
        if (
          response.role === "developer" ||
          response.tools.requestMoney.status !== "ACTIVE"
        )
          next("/")
        else next()
      } else if (
        to.matched.some((record) => record.meta.requireLogPermission)
      ) {
        if (response.tools.log.status !== "ACTIVE") next("/")
        else next()
      } else if (
        to.matched.some((record) => record.meta.requireIpgPermission)
      ) {
        if (["INACTIVE", "HARD_REJECT"].includes(response.tools.ipg.status)) {
          store.commit("callsnackbar", "دسترسی نقش برنامه‌نویس محدود شده است")
          next("/")
        } else {
          next()
        }
      } else if (
        to.matched.some((record) => record.meta.requireSubscriptionPermission)
      ) {
        if (response.tools.subscription.status != "ACTIVE") next("/")
        else next()
      } else if (
        to.matched.some((record) => record.meta.requireCustomerPermission)
      ) {
        if (
          !(
            response.tools.subscription.status === "ACTIVE" ||
            response.tools.customer.status === "ACTIVE"
          )
        )
          next("/")
        else next()
      } else if (
        to.matched.some((record) => record.meta.requireInvoicePermission)
      ) {
        if (response.tools.subscription.status !== "ACTIVE") next("/")
        else next()
      } else if (
        to.matched.some((record) => record.meta.requireSettingPermission)
      ) {
        if (response.role === "developer") next("/")
        else telegramId(to, next)
      } else if (
        to.matched.some((record) => record.meta.requireReportPermission)
      ) {
        if (response.role === "developer") next("/")
        else next()
      } else if (
        to.matched.some((record) => record.meta.requireKerdarPermission)
      ) {
        if (
          response.role === "developer" ||
          response.tools.kerdar.status !== "ACTIVE"
        )
          next("/")
        else next()
      } else if (
        to.matched.some(
          (record) => record.meta.requireBatchesSettlementPermission
        )
      ) {
        if (response.role === "developer") next("/")
        else next()
      } else if (
        to.matched.some((record) => record.meta.requirep2pPermission)
      ) {
        if (
          response.role === "developer" ||
          response.tools.p2p.status !== "ACTIVE"
        )
          next("/")
        else next()
      } else if (
        to.matched.some((record) => record.meta.requireHomePagePermission)
      ) {
        if (response.role === "developer") next("/")
        else next()
      } else if (
        to.matched.some((record) => record.meta.requireToolsPermission)
      ) {
        if (response.role === "admin" || response.role === "owner") next()
        else next("/")
      } else if (
        to.matched.some((record) => record.meta.requireMiandoPermission)
      ) {
        if (
          ["PENDING", "INACTIVE", "HARD_REJECT"].includes(
            response.tools.miando.status
          )
        )
          next("/")
        else next()
      } else next()
    })
    .catch((error) => {
      /*call api again*/
    })
  store.commit("isUserOrNot", false)
  payment(to, next)
  ipgPayment(to, next)
  if (store.state.business.role !== "developer") {
    store.dispatch("setBusinessCeilingAmount")
  }
  //business sentry
  if (store.state.is_sentry_active) {
    Sentry.configureScope(function (scope) {
      scope.setTag("businessName", to.path.split("/")[2])
    })
  }
}
const isBusinessOrNot = (to, next) => {
  //check if it's business page
  if (to.path.split("/")[1] == "business") {
    store.commit("setBusinessName", to.path.split("/")[2])
    retreiveBusiness(to, next)
  } else {
    //it is user
    store.commit("isUserOrNot", true)
    checkPermission(to, next)
    emailStatus(to, next)
    telegramId(to, next)
  }
}
const checkPermission = (to, next) => {
  //check if tools is enabled or not
  if (
    to.path.split("/")[2] == "request_money" &&
    store.state.user.tools.requestMoney.status != "ACTIVE"
  )
    next("/")
  else next()
}
const telegramId = (to, next) => {
  //check telegram chat id
  if (to.path.split("/")[3] == "telegramChatId") {
    store
      .dispatch("activateTelegramBot", to.path.split("/")[4])
      .then((response) => {
        next()
      })
      .catch((error) => {
        next()
      })
  } else next()
}

const emailStatus = (to, next) => {
  //check email activation status
  if (
    to.path.split("/")[2] == "email" &&
    to.path.split("/")[3] == "verify" &&
    to.path.split("/")[4] == "status"
  ) {
    if (to.path.split("/")[5] == 1) {
      var obj = {
        title: "ایمیل با موفقیت تایید شد",
        description: "برای تنظیم اعلان ها، به صفحه تنظیمات مراجعه کنید",
      }
      store.commit("callConfirmarionMessage", obj)
    } else if (to.path.split("/")[5] == 0) {
      var obj = {
        title: "ایمیل با موفقیت تایید نشد",
        description: "لطفا مجددا روی لینک دریافت شده در ایمیل خود کلیک کنید",
      }
      store.commit("callErrorMessage", obj)
    } else next()
  } else next()
}

const payment = (to, next) => {
  //check payment callback status
  if (to.path.split("/")[3] == "payment" && to.path.split("/")[4] == "status") {
    if (to.path.split("/")[5] == 1) {
      var obj = {
        title: "پرداخت با موفقیت انجام شد",
        description: "پرداخت شما برای ساخت کسب‌و‌کار جدید موفقیت آمیز بود",
      }
      store.commit("callConfirmarionMessage", obj)
    } else if (to.path.split("/")[5] == 0) {
      var obj = {
        title: "پرداخت با موفقیت انجام نشد",
        description: "پرداخت شما برای ساخت کسب‌و‌کار جدید موفقیت آمیز نبود",
      }
      store.commit("callErrorMessage", obj)
    } else next()
  } else next()
}

const ipgPayment = (to, next) => {
  //check payment callback status
  if (
    to.path.split("/")[3] == "ipg" &&
    to.path.split("/")[4] == "payment" &&
    to.path.split("/")[5] == "status"
  ) {
    if (to.path.split("/")[6] == 1) {
      var obj = {
        title: "پرداخت با موفقیت انجام شد",
        description: "پرداخت شما برای ارتقا به درگاه اختصاصی موفقیت آمیز بود",
      }
      store.commit("callConfirmarionMessage", obj)
    } else if (to.path.split("/")[6] == 0) {
      var obj = {
        title: "پرداخت با موفقیت انجام نشد",
        description: "پرداخت شما برای ارتقا به درگاه اختصاصی موفقیت آمیز نبود",
      }
      store.commit("callErrorMessage", obj)
    } else next()
  } else next()
}

router.beforeEach(async (to, from, next) => {
  if (getCookie("temp_access_token")) {
    const accessToken = getCookie("temp_access_token")
    eraseCookie("temp_access_token")
    setCookie({
      token: accessToken,
      name: "access_token",
      expireTime: getCookie("expiration_time"),
    })

    const refreshToken = getCookie("temp_refresh_token")
    eraseCookie("temp_refresh_token")
    setCookie({
      token: refreshToken,
      name: "refresh_token",
      expireTime: getCookie("expiration_time") * 2,
    })
  }

  if (getCookie("access_token")) {
    await store.commit("retrieveToken", getCookie("access_token"))
  }

  if (!getCookie("access_token") && getCookie("refresh_token")) {
    await store.dispatch("refreshToken")
  }
  if (
    !getCookie("access_token") &&
    !getCookie("refresh_token") &&
    to.path !== "/enter"
  ) {
    next("/enter")
  } else {
    if (to.matched.some((record) => record.meta.requireAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to loginpage.
      if (Object.keys(store.state.user).length == 0) {
        //set user object
        store
          .dispatch("retrieveUserObject")
          .then((response) => {
            isBusinessOrNot(to, next)
          })
          .catch((error) => {})
      } else isBusinessOrNot(to, next)
    } else if (to.matched.some((record) => record.meta.requireVisitors)) {
      if (store.getters.loggedIn) next("/")
      else next()
    } else next()
  }
})

router.afterEach((to, from) => {
  //after user left business erase its data
  if (from.path.split("/")[1] == "business") {
    if (to.path.split("/")[1] != "business") {
      store.commit("setBusiness", {})
    }
  }
  //when navigation drawer is going to show
  if (
    to.path.split("/")[1] == "business" ||
    to.path.split("/")[1] == "personal"
  ) {
    if (
      store.state.businessList == null ||
      (store.state.businessList != null && store.state.businessList.length == 0)
    ) {
      store
        .dispatch("retrieveBusinessList")
        .then((response) => {
          //do nothing
        })
        .catch((error) => {})
    }
  }
})

router.beforeResolve((to, from, next) => {
  /**
   * setting template
   */
  if (to.matched.some((record) => record.meta.template2)) {
    store.commit("changeTemplate", 2)
    next()
  } else if (to.matched.some((record) => record.meta.template3)) {
    store.commit("changeTemplate", 3)
    next()
  } else {
    store.commit("changeTemplate", 1)
    next()
  }
})

Vue.prototype.$http = axios

// costume directive
Vue.directive("role-permission", {
  inserted(el, binding, vnode) {
    let havePermission = true
    const store = vnode.context.$store
    el.style.display = "none"
    havePermission = checkRole(store.state.business.role, binding)

    if (!havePermission) {
      vnode.elm.parentElement.removeChild(vnode.elm)
    } else {
      el.style.display = null
    }

    // watch store
    store.watch(
      (state) => state.business.role,
      (role) => {
        {
          if (!store.state.isUser) {
            havePermission = checkRole(role, binding)
          }
        }
        if (!havePermission) {
          vnode.elm.parentElement.removeChild(vnode.elm)
        } else {
          el.style.display = null
        }
      }
    )
  },
})

function checkRole(currentRole, binding) {
  if (binding.arg === "not") {
    if (Array.isArray(binding.value)) {
      //check if user have this access role, permission is false
      if (binding.value.includes(currentRole)) return false
    } else if (typeof binding.value === "string") {
      //if user have this permission role, permission is false
      if (currentRole === binding.value) return false
    }
    //check role have access
    return true
  } else {
    if (Array.isArray(binding.value)) {
      if (!binding.value.includes(currentRole)) return false
    } else if (typeof binding.value === "string") {
      if (currentRole !== binding.value) return false
    }
    return true
  }
}

new Vue({
  render: (h) => h(app),
  store,
  router,
  components: { app },
  vuetify,
}).$mount("#app")
