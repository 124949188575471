<template>
  <v-toolbar flat height="68" class="fixed_toolbar">
    <v-btn icon @click.native="$emit('closeClick')">
      <v-icon color="van_color03" size="21"> close </v-icon>
    </v-btn>

    <v-toolbar-title class="pr-0">
      {{ getToolbarTitle }}
    </v-toolbar-title>
    <v-spacer />

    <v-toolbar-items
      v-if="whitchBtn && checkShowingTheCancelButtonCondition"
      class="align-center"
    >
      <v-btn
        ref="btn"
        :outlined="whitchBtn == 'cancleBtn' ? true : false"
        :dark="whitchBtn == 'cancleBtn' ? false : true"
        text
        class="btn_small_normal"
        :loading="whitchBtn == 'cancleBtn' ? loading : false"
        :disabled="
          whitchBtn == 'cancleBtn' || whitchBtn == 'reviewBtn'
            ? disableBtn
            : false
        "
        @click="$emit('toolbarBtnClick')"
      >
        {{ btnTitle }}
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { channels } from "@/mixins/channels.js"

export default {
  name: "Toolbar",
  mixins: [channels],
  props: [
    "disableBtn",
    "loading",
    "statusChannel",
    "addRefundModal",
    "isRefunded",
    "shouldRemoveTheCancelButton",
  ],
  data() {
    return {
      channelName: "",
    }
  },
  computed: {
    btnTitle() {
      const titles = {
        cancleBtn: "لغو برداشت",
        returnBtn: "بازگشت وجه",
        reviewBtn: "بازبینی درخواست",
      }
      return this.whitchBtn ? titles[this.whitchBtn] : ""
    },
    checkShowingTheCancelButtonCondition() {
      return !(
        this.whitchBtn === "cancleBtn" && this.shouldRemoveTheCancelButton
      )
    },

    whitchBtn() {
      if (
        [
          "settlement_tashim_pending",
          "settlement_tashim_unsuccessful",
        ].includes(this.statusChannel)
      ) {
        return "cancleBtn"
      } else if (
        !this.isRefunded &&
        this.$store.state.business.hasOwnProperty("tools") &&
        this.$store.state.business.tools.refund.status == "ACTIVE" &&
        [
          "transaction_requestMoney_successful",
          "transaction_ipg_successful",
        ].includes(this.statusChannel) &&
        !["developer", "observer", "operator"].includes(
          this.$store.state.business.role
        )
      ) {
        return "returnBtn"
      } else if (this.addRefundModal) {
        return "reviewBtn"
      } else return null
    },
    getToolbarTitle() {
      return (
        this.getKeyByValue(this.transactionsTitles, this.statusChannel) ??
        "جزییات تراکنش"
      )
    },
  },
  methods: {
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key].includes(value))
    },
  },
}
</script>
